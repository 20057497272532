import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./LandingPageNavigation.css";
import Button from "../primitives/Button/Button";
import Logo from "../primitives/Logo/Logo";
import { DiscordIcon } from "../CustomIcons/DiscordIcon";

const LINKS = [
  {
    title: "Dashboard",
    href: "/dashboard",
  },
  {
    title: "Leaderboard",
    href: "/leaderboard",
  },
  // {
  //   title: "Home",
  //   href: "/",
  // },
  // {
  //   title: "Services",
  //   href: "/services",
  // },
  // {
  //   title: "Reviews",
  //   href: "/reviews",
  // },
  // Removing since this is an external link and shouldn't use <Link>
  // {
  //   title: "Support",
  //   href: "/Support",
  // },
];

const LandingPageNavigation = ({ ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const handleDiscord = () => {
    window.open(
      "https://discord.gg/5M4CbvdMVd",
      '_blank'
    );
  };

  return (
    <header>
      <div className="landing-page-navigation">
        <div className="title">
          <Logo isLandingPage={true} isSideBarExpanded={true}/>
        </div>
        <nav>
          <ul className="header-nav">
            {LINKS.map((link) => (
              <li key={`link-desktop-${link.title}`}>
                <Link
                  className={`header-nav-item header-nav-item--${link.title.toLowerCase()}`}
                  to={link.href}
                >
                  {link.title}
                </Link>
              </li>
            ))}
            <li key={`link-desktop-knowledge-base header-nav-item header-nav-item--knowledge-base`}>
              <p className="header-nav-item header-nav-item--support" onClick={() => window.open("https://docs.chart.observer/knowledge-base", "_blank", "noopener,noreferrer")}>
                Support
              </p>
            </li>
            <li key={`link-desktop-faq header-nav-item header-nav-item--faq`}>
              <p className="header-nav-item header-nav-item--support" onClick={() => window.open("https://docs.chart.observer/faq/", "_blank", "noopener,noreferrer")}>
                FAQ
              </p>
            </li>
          </ul>
        </nav>
        <div className="header-buttons">
          <Button
            kind="tertiary"
            hover={true}
            onClick={() => navigate("/login")}
          >
            Login
          </Button>
          <Button kind="primary" hover={true} onClick={() => navigate("/signup")}>
            Sign Up
          </Button>
        </div>
        <div className="hamburger-button-container">
          <Button kind="tertiary" onClick={() => setIsOpen(true)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z"
                fill="black"
                fillOpacity="0.56"
              />
            </svg>
          </Button>
        </div>
      </div>
      <div
        className={`mobile-side-container ${
          isOpen ? "mobile-side-container--open" : ""
        }`}
      >
        <div className="close-button-container">
          <div className="title">
            <h1>
              <Logo isLandingPage={false} isSideBarExpanded={true}/>
            </h1>
          </div>

          <Button kind="tertiary" onClick={() => setIsOpen(false)}>
            X
          </Button>
        </div>
{/*         <nav>
          <ul className="mobile-header-nav">
            {LINKS.map((link) => (
              <li key={`link-mobile-${link.title}`}>
                <Link
                  className={`header-nav-item mobile-header-nav-item header-nav-item--${link.title.toLowerCase()}`}
                  to={link.href}
                >
                  {link.title}
                </Link>
              </li>
            ))}
            <li key={`link-mobile-faq`}>
              <p className="header-nav-item mobile-header-nav-item header-nav-item--support" onClick={() => window.open("https://docs.chart.observer/faq", "_blank", "noopener,noreferrer")}>
                FAQ
              </p>
            </li>
          </ul>
        </nav> */}
        <nav>
          <ul className="mobile-header-nav">
            {LINKS.map((link) => (
              <li key={`link-mobile-${link.title}`}>
                <Link
                  className={`header-nav-item mobile-header-nav-item header-nav-item--${link.title.toLowerCase()}`}
                  to={link.href}
                >
                  {link.title}
                </Link>
              </li>
            ))}
            <li key={`link-mobile-knowledge-base`}>
              <p className="header-nav-item mobile-header-nav-item header-nav-item--support" onClick={() => window.open("https://docs.chart.observer/knowledge-base", "_blank", "noopener,noreferrer")}>
                Support
              </p>
            </li>
            <li key={`link-mobile-faq`}>
              <p className="header-nav-item mobile-header-nav-item header-nav-item--support" onClick={() => window.open("https://docs.chart.observer/FAQ", "_blank", "noopener,noreferrer")}>
                FAQ
              </p>
            </li>
            <li key={`link-mobile-support`} onClick={handleDiscord}>
              <div className="mobile-header-nav-discord">
                <p className="header-nav-item mobile-header-nav-item header-nav-item--support">
                  Discord
                </p>
                <DiscordIcon className="topbar-icon"/>
              </div>
            </li>

          </ul>
        </nav>
        <div className="mobile-header-buttons">
          <Button
            kind="tertiary"
            onClick={() => {
              setIsOpen(false);
              navigate("/login");
            }}
          >
            Sign In
          </Button>
          <Button
            kind="primary"
            onClick={() => {
              setIsOpen(false);
              navigate("/signup");
            }}
          >
            Free Trial
          </Button>
        </div>
      </div>
    </header>
  );
};

export default LandingPageNavigation;
