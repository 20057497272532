import React, { useState } from "react";

import "./WebhookUrlInstructions.css";

import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import WebhookSettings from "../../images/webhook_settings_light.png";
import WebhookNotifications from "../../images/webhook_notifications_light.png";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useAuth } from "../../hooks/useAuth";
import { usePageTracking } from "../../hooks/usePageTracking";


const WebhookUrlInstructions = () => {
  const { user } = useAuth();

  const [showCopyMessage, setShowCopyMessage] = useState(false);

  usePageTracking();
  

  const copyText = () => {
    setShowCopyMessage(false);

    const webhookURL = `https://g2uyqqluc4.execute-api.us-east-2.amazonaws.com/dev/transaction/${(user && user.webhookId) ? user.webhookId : "xxxxxxx"}`
    navigator.clipboard.writeText(webhookURL)
      .then(() => {
        setShowCopyMessage(true);

        const _ = setTimeout(() => {
          setShowCopyMessage(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      })
  }

  return (
    <DashboardLayout page={"Webhook Instructions"}>
      <div className="webhook-url-instructions">
        <div className="webhook-url-instructions-content glass-surface-module">
          <h2 className="webhook-info-title text-header">
            Tradingview Integration
          </h2>
          <h2 className="webhook-bullet text-header">
            <p className="text-base">
              <h1>Step 1:</h1> 
                <ul>
                  <li>Check out the <a href='https://docs.chart.observer/knowledge-base/automating-crypto-trades/sending-trade-signals/'  target='_blank' rel="noreferrer">video walkthrough</a> for the most basic implementation of alerts.</li>
                  <li>Create an alert that triggers when your desired trade execution condition is met.</li>
                  <li>Alerts can be based on price (easiest), line drawings, indicators (more advanced), or on strategies (complex strategy scripts)</li>
                  <li>If you are brand new to Tradingview, you can find their instructions on <a target='_blank' href='https://www.tradingview.com/support/solutions/43000520149-about-tradingview-alerts/?aff_id=134695'>setting alerts</a>,
              and you will also want to read up on <a target='_blank' href='https://www.tradingview.com/chart/b1S1ask9/?solution=43000529348?aff_id=134695'>webhooks</a>.             
                  </li>
                  <li><b>Remember</b> to only set alerts on <b>USD, USDT, or USDC</b> trading pairs available on Coinbase.  <a target='_blank' href='https://www.tradingview.com/watchlists/138162224/?aff_id=134695'>Check the list</a> here first!</li>
                  <ul><li>Common examples:  BTCUSD (Bitcoin), ETHUSD (Ethereum), SOLUSD (Solana), XRPUSD (Ripple), DOGEUSD (Doge) etc.</li></ul>
                </ul>
            </p>
            <p>&nbsp;</p>
          </h2>
          <h2 className="webhook-bullet text-header">
            <p className="text-base">
              <h1>Step 2:</h1> 
                <ul>
                  <li>When creating an alert on the <a target='_blank' href='https://www.tradingview.com/?aff_id=134695'>Tradingview platform</a>, you will see a <b>Settings</b> tab, where you will find the <b>Message</b> box. </li>
                  <li>This is the message that gets automatically sent to your unique ChartObserver webhook URL (provided in Step 3).</li>
                  <li>Using the Message field in your Tradingview alert, insert the text we have provided in the gray box shown below. </li>
                  <li>Carefully edit the necessary portions of the message (count, action, etc.). Avoid deleting any quotation marks or commas.</li>
                  <li>If the Message box turns red, you have an error in your formatting. </li>
                  <li>If you can't resolve the error, you can troubleshoot your message content in a JSON validator like <a target='_blank' href='https://jsonlint.com/'>jsonlint.com</a>.</li>
              </ul>
            </p>
          </h2>
          <div className="webhook-alert-container">
            <img src={WebhookSettings} alt="webhook-alert" className="webhook-url-instructions-image" />
            <div className="code-snippet">
              <p className="text-base">
                &#123;
                <br /> "tokenpair":"&#123;&#123;ticker&#125;&#125;",
                <br /> "count":"0.1",
                <br /> "usdprice":"&#123;&#123;close&#125;&#125;",
                <br /> "action":"buy",
                <br /> "strategy_author":"",
                <br /> "interval":"&#123;&#123;interval&#125;&#125;",
                <br /> "exchange":"&#123;&#123;exchange&#125;&#125;",
                <br /> "user":"<span className="webhook-url-instructions-span">{(user && user.userId) ? user.userId : "xxxxxxx"}</span>"
                <br /> &#125;
              </p>
            </div>
          </div>
          <h2 className="webhook-bullet text-header">
            <p className="text-base">
              <h2>Let's take a look at each parameter in the above example that buys 0.1 Bitcoin:</h2>
              <ul>       
                <li><strong>"tokenpair"</strong>
                  <br />This is the cryptocurrency you wish to trade using your USD (play money) balance.  USD is the "base" currency in the "pair".
                  <br />On Tradingview, the &#123;&#123;ticker&#125;&#125; placeholder will be automatically filled with the token pair for whatever chart you are viewing.  
                  <br />NOTE: USDT and USDC pairs will be converted to USD by ChartObserver upon recieivng your trade signal.
                  <br />For example, the &#123;&#123;ticker&#125;&#125; will always be BTCUSD if you are charting Bitcoin with BTCUSDC, BTCUSDT, or BTCUSD. 
                </li>  
                <p>&nbsp;</p>       
                <li><strong>"count"</strong>
                  <br />This is the number of tokens you want to buy or sell. A "buy" order must be a whole number or a decimal.
                  <br />When placing a sell order, you have the option of using a % of your total holdings, otherwise use a whole number or a decimal.
                  <br />For example, if you want to sell 10% of your holdings, you would enter 10% in the count field.
                  <br />If you want to sell 0.05 of your BTCUSD, you would enter 0.05 in the count field.
                  <br />Orders will be rejected if the buy amount is greater than your available USD, or the sell amount is greater than your holdings.
                </li>
                <p>&nbsp;</p>
                <li><strong>"usdprice"</strong>
                <br />On Tradingview, the &#123;&#123;close&#125;&#125; placeholder will be automatically filled with the closing price for the candlestick that triggered the alert.
                <br />Please note that ChartObserver will lookup the current price anyway.  This is just for your reference, if you are reviewing your alert history on Tradingview.              
                <br />Therefore, the actual execution price may be slightly different.  Price "slippage" is typical for most trading platforms.
                </li> 
                <p>&nbsp;</p>
                <li><strong>"action"</strong>
                <br />This value must be either "buy" or "sell".  And it is case-sensitive.
                <br />If you are adding an alert to a Tradingview <a target='_blank' href='https://www.tradingview.com/support/solutions/43000481368-strategy-alerts/?aff_id=134695'>strategy</a>, you can use
                the &#123;&#123;strategy.order.action&#125;&#125; placeholder, which is automatically filled with "buy" or "sell".
                <br />One advantage of alerting with a strategy, instead of an indicator or drawing, is that you only use up only 1 of your allowed alerts.
                <br />Otherwise, you will typically need to create a buy alert, and a separate sell alert on Tradingview.
                <br />Tradingview has several subscription levels, and the number of alerts is limited by your subscription level.
                <br />A the time of this writing, the free plan allows for 5 price alerts, and 1 indicator alert. Paid plans have up to 400 non-expiring alerts.
                </li> 
                <p>&nbsp;</p>
                <li><strong>"strategy_author"</strong>
                <br /><strong>OPTIONAL: </strong>Leave this blank unless you wish to keep track of the strategy or indicator that triggered the alert.
                <br />We will store the name of the strategy and the author with each order. This will support a feature that we plan to release at a later date.
                <br />The correct format is "indicator_authorname". For example, "Price Action Concepts_LuxAlgo" if using <a target='_blank' href='https://www.luxalgo.com/library/indicator/luxalgo-price-action-concepts/'>Price Action Concepts</a> by LuxAlgo.
                <br />This is intended to track both Indicators and Strategies. 
                </li> 
                <p>&nbsp;</p>
                <li><strong>"interval"</strong>
                <br />On Tradingview, the &#123;&#123;interval&#125;&#125; placeholder will be automatically filled with the timeframe of the chart.
                <br />For example, if you are charting on the 30 minute candlesticks timeframe, the &#123;&#123;interval&#125;&#125; will be "30".
                <br />This is for a feature that we plan to release at a later date, intended to help identify the most effective timeframes for your trading strategies.
                </li> 
                <p>&nbsp;</p>
                <li><strong>"exchange"</strong>
                <br />On Tradingview, the &#123;&#123;exchange&#125;&#125; placeholder will be automatically filled with the trading exchange associated with the ticker.
                <br />For example, if you are charting BTCUSD on Coinbase, the &#123;&#123;exchange&#125;&#125; will be "Coinbase".
                
                </li> 
                <p>&nbsp;</p>
                <li><strong>"user"</strong>
                <br />This is your user id for ChartObserver
                <br />This is how we know which account to apply the order to.
                <br /><strong>DO NOT</strong> share your user id and webhook URL with anyone. They could possibly manipulate your account.
                </li> 
                <p>&nbsp;</p>
                <p>Please remember that all orders sent to our platform are only simulated orders using play-money, often referred to as “paper-trading”.</p>
                
              </ul>
            </p>
          </h2>
          <hr className="webhook-url-instructions-hr" />
          <hr/>
          <h2 className="webhook-bullet text-header">
            <p className="text-base">
              <h1>Step 3:</h1> 
                <ul>
                  <li>In the <b>Notifications</b> tab for the Tradingview alert, check the box for <b>Webhook URL</b>, and enter the URL we provided here.</li>
                  <li>It needs to be all one line, so you may want to use the copy button to send it to your clipboard.</li>
                  <li>This is your unique webhook URL for your account. <b>DO NOT</b> share this URL with anyone!</li>
                  <li>Feel free to also check any of the other boxes to be alerted in various ways</li>
                </ul>  
            </p>
          </h2>
          
          <div className="webhook-alert-container">
            <img src={WebhookNotifications} alt="webhook-alert" className="webhook-url-instructions-image" />
            <div className="code-snippet">
              <h2 className="code-snippet-header text-base">Your Webhook URL for ChartObserver:</h2>
              <div className="webhook-url-copy-container">
                <ContentCopyIcon className="copy-icon" onClick={copyText} />
                <p className="text-base">
                  <span className="webhook-url-instructions-span">https://g2uyqqluc4.execute-api.us-east-2.amazonaws.com/dev/transaction/{(user && user.webhookId) ? user.webhookId : "xxxxxxx"}</span>
                </p>
              </div>
              {showCopyMessage && <p className="text-small">Copied to clipboard!</p>}
            </div>
          </div>
          
        </div>
      </div>
    </DashboardLayout>
  );
};

export default WebhookUrlInstructions;
