import { Box, Typography } from "@mui/material";

import "./Column.css";

const fontSize = 14;

export const columns = [
    { 
        field: "id", 
        headerName: "Token", 
        flex: 1,
        cellClassName: "--cell --token-cell --cell-id",
        renderCell: ({ row: {id}}) => {
            return(
                <>
                    {id.slice(0,-3)}
                </>
            )
        }
    },
    { 
        field: "amount", 
        headerName: "Amount",
        flex: 1,
        cellClassName: "--cell",
        renderCell: ({row: {amount}}) => {
            return(
                <Typography fontSize={fontSize}>
                    {(amount % 1 === 0) ? amount.toFixed(0) : (amount > 9999 ? amount.toFixed(0) : (amount < 10 ? amount.toFixed(5) : amount.toFixed(2)))}
                </Typography>
            )
        }
    },
    { 
        field: "totalCost", 
        headerName: "Total Cost",
        flex: 1,
        cellClassName: "--cell",
        renderCell: ({ row: {totalCost}}) => {
            return(
                <Typography fontSize={fontSize}>
                    {totalCost.toLocaleString("en-US", {style: "currency", currency: "USD"})}
                </Typography>
            );
        }
    },
    { 
        field: "avgPrice",
        headerName: "Avg. Price",
        flex: 1,
        cellClassName: "--cell",
        renderCell: ({ row: {avgPrice}}) => {
            return(
                <Typography fontSize={fontSize}>
                    {/* {avgPrice.toLocaleString("en-US", {style: "currency", currency: "USD"})} */}
                    {avgPrice < 1 ? `$${(avgPrice).toFixed(6)}` : avgPrice.toLocaleString("en-US", {style: "currency", currency: "USD"})}
                </Typography>
            );
        }
    },
    { 
        field: "currentPrice",
        headerName: "Current Price",
        flex: 1,
        cellClassName: "--cell",
        renderCell: ({ row: {currentPrice}}) => {
            // Format price to have 2 or more decimal places (if it has a decimal)
            // const priceString = currentPrice.toString();
            // var formatedPrice = currentPrice;
            // if (priceString.includes(".")) {
            //     if (priceString.split(".")[1].length < 2) {
            //         formatedPrice = currentPrice.toFixed(2);
            //     }
            // }
            return(
                <Typography fontSize={fontSize}>
                    {currentPrice < 1 ? `$${currentPrice.toFixed(6)}` : currentPrice.toLocaleString("en-US", {style: "currency", currency: "USD"})}
                </Typography>
            );
        }
    },
    { 
        field: "age", 
        headerName: "Age",
        flex: 1,
        cellClassName: "--cell",
        renderCell: ({ row: { age }}) => {
            let formatAge = ""
            if ( age < 1) {
                formatAge = `${Math.floor(age * 60)}m`;
            } else if (age < 24) {
                formatAge = `${Math.floor(age)}h`;
            } else {
                formatAge = `${Math.floor(age / 24)}d`;
            }
            
            return(
                <Typography fontSize={fontSize}>
                        {formatAge}
                </Typography>
            );
        }
    },
    { 
        field: "profit", 
        headerName: "Profit",
        flex: 1,
        cellClassName: "--cell",
        renderCell: ({ row: { profit }}) => {
            const profitColor = profit >= 0
                ? "#00A652" //green
                : "#ED1B24" //red
            return(
                <Typography color={profitColor} fontSize={fontSize}>
                    {profit.toFixed(2)}%
                </Typography>
            );
        }
    },
]
