import { ResponsiveTreeMap } from '@nivo/treemap';
import { useTooltip, BasicTooltip } from '@nivo/tooltip';
import { chartTheme } from '../cards/modals/ChartTheme';

import { useTheme } from '../../hooks/useTheme';
import { useEffect, useRef, useState } from 'react';

const TreeMapChart = ({chartData, chartSettings}) => {

    console.log(chartData)

    const { isDarkMode } = useTheme();

    const textColor = isDarkMode ? "#e0e0e0" : "#333333";

    return(
        <ResponsiveTreeMap
            theme={chartTheme(isDarkMode)}
            data={chartData}
            identity="name"
            value="loc"
            valueFormat=".02s"
            leavesOnly={true}
            nodeComponent={(nodeProps) => (<CustomNode {...nodeProps} chartSettings={chartSettings}/>)}
            margin={chartSettings.margin}
            colors={{ datum: 'data.color' }}
            label="id" // This can be a function! use for showing profit on hover
            labelSkipSize={12}
            labelTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.2
                    ]
                ]
            }}
            orientLabel={false}
            parentLabelPosition="left"
            parentLabelTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        2
                    ]
                ]
            }}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.1
                    ]
                ]
            }}
        />
    );
}

export default TreeMapChart;


const CustomNode = ({ node, chartSettings }) => {

    const { showTooltipFromEvent, hideTooltip } = useTooltip();

    const {isMobile} = useTheme();

    const imgContainerRef = useRef(null);
    const divRef = useRef(null);
    const infoContainerRef = useRef(null);

    const [imgContainerSize, setImgContainerSize] = useState(null);
    const [divSize, setDivSize] = useState(null);
    const [infoContainerSize, setInfoContainerSize] = useState(null);

    const [showImageContainer, setShowImageContainer] = useState(true);
    const [showInfoContainer, setShowInfoContainer] = useState(true)

    const [infoContainerRotation, setInfoContainerRotation] = useState(0);

    const boxStyle = {
        width: "100%",
        height: "100%",
        padding: "2px",

    };

    const innerBorder = {
        display: "flex",
        width: "100%",
        height: "100%",
        background: `${node.data.color}`,
        // border: `1px solid ${borderColor}`,
        border: `1px solid ${node.data.border}`,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: `inset 0px 0px 20px 5px ${node.data.border}`
    };

    const infoContainerStyle = { display: "flex", 
        flexDirection: "column", 
        textAlign: "center", 
        justifyContent: "center", 
        alignItems: "center",
        transform: `rotate(${infoContainerRotation}deg)`
    };

    const imgStyle = {
        width: isMobile || chartSettings.smallIcons ? "1.5rem" : "3rem",
        height: isMobile || chartSettings.smallIcons ? "1.5rem" : "3rem",
    };

    useEffect(() => {
        if (imgContainerSize && divSize && infoContainerSize) {
            if (imgContainerSize.width > divSize.width || infoContainerSize.width > divSize.width || imgContainerSize.height + infoContainerSize.height > divSize.height) {
                // Image Fits but info does not
                if (imgContainerSize.width < divSize.width && imgContainerSize.height < divSize.height) {
                    setShowInfoContainer(false);
                } else if (infoContainerSize.width < divSize.width && infoContainerSize.height < divSize.height) {
                    setShowImageContainer(false);
                } else if (infoContainerSize.height < divSize.width && infoContainerSize.width < divSize.height) {
                    // Info text can fit rotated
                    setShowImageContainer(false);
                    setInfoContainerRotation(90)
                } else {
                    setShowImageContainer(false);
                    setShowInfoContainer(false);
                }
            }
        }
    }, [imgContainerSize, divSize, infoContainerSize]);

    useEffect(() => {
        // Can't think of any cases where this imgConatiner and infoContainer would change size, but watching for it just in case
        const imgContainerObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                setImgContainerSize({
                    width: entry.contentRect.width,
                    height: entry.contentRect.height
                });
            }
        });
        const infoContainerObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                setInfoContainerSize({
                    width: entry.contentRect.width,
                    height: entry.contentRect.height
                });
            }
        });

        const divObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                setDivSize({
                    width: entry.contentRect.width,
                    height: entry.contentRect.height
                });
            }
        });

        if (imgContainerRef.current) imgContainerObserver.observe(imgContainerRef.current);
        if (divRef.current) divObserver.observe(divRef.current);
        if (infoContainerRef.current) infoContainerObserver.observe(infoContainerRef.current);

        // Cleanup observer on unmount
        return () => {
            imgContainerObserver.disconnect();
            divObserver.disconnect();
            infoContainerObserver.disconnect();
        };
    }, []);

    return(
        <foreignObject 
            x={node.x} 
            y={node.y} 
            width={node.width} 
            height={node.height}
            onMouseEnter={(event) => 
                showTooltipFromEvent(
                    // Replace this with a better toop tip use <TooltipWrapper /> if possible
                    <BasicTooltip
                        id={node.data.name}
                        value={`${node.data.loc.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })} (${node.data.profit.toFixed(2)}%)`}
                        color={node.data.border}
                        enableChip
                    />,
                    event
                )
            }
            onMouseLeave={hideTooltip}
        >
            <div className="customNode" style={boxStyle}>
                <div style={{backgroundColor: "#0a0d0d", display: "flex", width: "100%", height: "100%"}}>
                    <div style={innerBorder} ref={divRef}>
                        
                        <div style={infoContainerStyle} ref={imgContainerRef}>
                            { showImageContainer ? (
                                <>
                                    {node.data.img && 
                                        <img
                                            src={node.data.img}
                                            alt={node.data.name}
                                            style={imgStyle}
                                        />
                                    }
                                </>
                            ) : (
                                <></>
                            )}
                        </div>

                        {showInfoContainer &&
                            <div style={infoContainerStyle} ref={infoContainerRef}>
                                <p className={`${chartSettings.smallIcons ? "text-tiny" : "text-small"} text-white `}>{node.data.name}</p>
                                <p className={`${chartSettings.smallIcons ? "text-tiny" : "text-xsmall"} text-white `}>({node.data.profit.toFixed(2)}%)</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </foreignObject>
    )
}