import React from "react";
import "./styles/PageFive.css";

import CheckIcon from "@mui/icons-material/Check";
import NewsletterImage from "../assets/newsletter_image.png";
import Button from "../../../components/primitives/Button/Button";
import { useNavigate } from "react-router-dom";



const PageFive = () => {
  const navigate = useNavigate();
  return (
    <section id="page-five" className="page-five landing-page-padding">
      <div className="page-wrapper">
        <h1 className="page-five-header text-heading text-black">
          First 50 trades are free each month
        </h1>

        <div className="page-five-card-grid">
          <div className="plan">
            <div className="plan-price-container">
              <h2 className="black-name">Basic</h2>
              <div className="plan-price">
                <h1 className="blue-price">$0</h1>
                <p className="plan-price-month">/month</p>
              </div>
              <p className="plan-price-container-subtext">
                Risk-free trade simulation
              </p>
              <hr />
            </div>

            <div className="plan-info">
              
              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">$10,000 in play money</p>
              </div>

              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">50 Free trades per month</p>
              </div>

              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">Simulated BUY/SELL</p>
              </div>

              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">Any <a href=''>Coinbase-listed</a> tokens</p>
              </div>                

              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">Trading API for 3rd party signals <br />(bots, webhooks)</p>
              </div>       

              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">Supports Tradingview Alerts</p>
              </div>  

              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">Growing list of integrations</p>
              </div>  

              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">Copy trade other users<br />(coming soon)</p>
              </div> 

              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">Rewards for sharing & referrals<br /> (coming soon)</p>
              </div>     

            </div>

            <div className="plan-button-container">
              <Button
                kind="plan"
                onClick={() => {
                  navigate("/signup");
                }}
              >
                Get Started
              </Button>
            </div>
          </div>

          {/* Basic Plan */}
          <div className="plan plan--blue">
            <div className="plan-price-container">
              <h2 className="white-name">Swing Trader</h2>
              <div className="plan-price">
                <h1 className="white-price">$9.95</h1>
                <p className="plan-price-month-white">/month</p>
              </div>
              <p className="plan-price-container-subtext-white">
                For Active Traders
              </p>
              <hr />
            </div>

            <div className="plan-info">
              <div className="info-bullet">
                <div className="check-icon-white">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text-white">
                  All Basic plan features, plus...
                </p>
              </div>

              <div className="info-bullet">
                <div className="check-icon-white">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text-white">
                  500 Trades per month 
                </p>
              </div>

              <div className="info-bullet">
                <div className="check-icon-white">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text-white">
                  Priority Support <br />(Discord Channel)     
                </p>
              </div>      
            </div>

            <div className="plan-button-container">
              <button className="plan-button-white">Purchase</button>              
            </div>
          </div>

          <div className="plan">
            <div className="plan-price-container">
              <h2 className="black-name">Day Trader</h2>
              <div className="plan-price">
                <h1 className="blue-price">$24.95</h1>
                <p className="plan-price-month">/month</p>
              </div>
              <p className="plan-price-container-subtext">
                Great for Bots, Signals, & Algos 
              </p>
              <hr />
            </div>

            <div className="plan-info">
              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">All Swing Trader features, plus...</p>
              </div>

              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">1,500 Trades per month</p>
              </div>

              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">VIP Support <br />(Private Discord Channel)</p>
              </div>

              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">Create a Trading Team</p>
              </div>

              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">Help coding automated bots <br />(Tradingview Pine Script)</p>
              </div>
            </div>

            <div className="plan-button-container">
            <Button kind="plan" hover={true} onClick={() => {window.open("https://docs.chart.observer/contact-us/", '_blank', 'noopener,noreferrer')}}>
                Contact Us
              </Button>
            </div>
          </div>
          {/* Pro Trader Plan */}
          <div className="plan">
            <div className="plan-price-container">
              <h2 className="black-name">Influencer</h2>
              <div className="plan-price">
                <h1 className="blue-price">$99.95</h1>
                <p className="plan-price-month">/month</p>
              </div>
              <p className="plan-price-container-subtext">
                Reputation builder package
              </p>
              <hr />
            </div>

            <div className="plan-info">
              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">All Day Trader features, Plus...</p>
              </div>

              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">Unlimited Trading</p>
              </div>

              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">Affiliate revenue <br />(coming soon)</p>
              </div>

              <div className="info-bullet">
                <div className="check-icon-blue">
                  <CheckIcon fontSize="smaller" />
                </div>
                <p className="info-bullet-text">Fees waived for qualifying KOLs</p>
              </div>           

            </div>

            <div className="plan-button-container">
              <Button kind="plan" hover={true} onClick={() => {window.open("https://docs.chart.observer/contact-us/", '_blank', 'noopener,noreferrer')}}>
                Contact Us
              </Button>
            </div>
          </div>
          {/* Pro Trader Plan */}
          
        </div>

        <div>Academic discount available for student groups, colleges, and universities.  <a href="https://docs.chart.observer/contact-us/">Contact Us</a> for details.</div>
        <p>&nbsp;</p>
        {/* News Letter */}
        <div className="news-letter">
          <div className="news-letter-content">
            <h1 className="text-heading text-dark-blue">
              Subscribe to our newsletter
            </h1>
            <p className="text-paragraph">
             Stay up to date with all the latest ChartObserver News and Promotions. Be the first to know when a new feature drops!
            </p>
            <div className="call-to-action">
              {/* <input placeholder="Enter email address" /> */}
              <Button kind="primary" hover={true} onClick={() => {window.open("https://docs.chart.observer/newsletter", '_blank', 'noopener,noreferrer')}}>
                Subscribe
              </Button>
            </div>
          </div>
          <img src={NewsletterImage} alt="newsletter" />
        </div>
      </div>
    </section>
  );
};

export default PageFive;
