import "./Dashboard.css";
import React, { useEffect, useState } from "react";

import { useAuth } from "../../hooks/useAuth";
import { useTheme } from "../../hooks/useTheme";

import { getClosedTradesByUserId, getCoinBaseData, getOpenPositionsByUserId, getUserBalance } from "../../helpers/api";
import { formatDataForGrid } from "../../helpers/formatOpenPostions";
import { removeUnsupportedTokens } from "../../helpers/removeUnsupportedTokens";

import OpenPositions from "../../components/postions/OpenPositions";
import PortfolioMetrics from "../../components/portfolioMetrics/PortfolioMetrics";
import Spinner from "../../components/loadingAnimations/Spinner";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import { OpenPositionsTable } from "../../components/Table/OpenPositionsTable";
import TradeInstructions from "../../components/TradeInstructions/TradeInstructions";
import Activity from "../../components/Activity/Activity";

import Cookies from "js-cookie";
import { WalkthroughModal } from "../../components/WalkthroughModal/WalkthroughModal";
import { usePageTracking } from "../../hooks/usePageTracking";
import BragCardModal from "../../components/Activity/BragCardModal";

import { OpenPositionsSpinner } from "../../components/postions/OpenPositionsSpinner";

const Dashboard = () => {

  const { user } = useAuth();
  const { isMobile } = useTheme();

  // Page Title Setting for GA4
  usePageTracking();

  const [openPositionData, setOpenPositionData] = useState(null);

  const [userBalance, setUserBalance] = useState(null);

  const [showOpenPositionTableSpinner, setShowOpenPositionTableSpinner] = useState(true);

  const [showFirstTimeLoginModal, setShowFirstTimeLoginModal] = useState(false);

  // Closed Trades 
  const [closedTrades, setClosedTrades] = useState(null);
  const [showClosedTradesSpinner, setShowClosedTradesSpinner] = useState(true);

  // Metric States
  const [tradePerformance, setTradePerformance] = useState(null);
  const [highestPostionProfit, setHighestPositionProfit] = useState(null);
  const [portfolioValue, setPortvolioValue] = useState(null);
  const [averageTradePercent, setAverageTradePercent] = useState(null);
  const [showTreeMapCardSpinner, setShowTreeMapCardSpinner] = useState(true);

  const [showBragCardModal, setShowBragCardModal] = useState(false);
  const [bragCardTrade, setBragCardTrade] = useState(null);
  const [awsAPIError, setAwsAPIError] = useState(false);

  const date = new Date();

  // Close modal and set reshowing of modal to false or true based on modal checkbox
  const handleWalkthroughModalClose = (isReshowModal) => {
    Cookies.set("showFirstTimeLogin", isReshowModal);
    setShowFirstTimeLoginModal(false);

  } 

  const fetchDataForTables = async () => {
    // this will call both API calls at once and await a return of the promise befor proceeding into the .then
    return Promise.all([
      getOpenPositionsByUserId(user.userId),
      getCoinBaseData(),
      getUserBalance(user.userId),
    ]).then(([openTransactionsData, coinbaseResponse, userBalance]) => {

      // Deconstructing the reutrn array from .all into the data that is returned from the functions.
      if (openTransactionsData.status !== 200) {
        setAwsAPIError(true);
        return
      }

      if (coinbaseResponse.status === 200) {
        const coinbaseDataObj = coinbaseResponse.data;
        const supportedOpenTransacitonData = removeUnsupportedTokens(openTransactionsData.data, coinbaseDataObj);
        const formatedData = formatDataForGrid(supportedOpenTransacitonData, coinbaseDataObj);

        if (formatedData.length !== 0) setOpenPositionData(formatedData);
      }
      setShowOpenPositionTableSpinner(false);
      setShowTreeMapCardSpinner(false);
      setUserBalance(userBalance);

    });
  };

  const fetchClosedTrades = async () => {
    return Promise.all([
      getClosedTradesByUserId(user.userId, 10),
    ]).then(([closedTradeData]) => {
      // Check if an array was returned. If not, it is an API Error response TODO: check with correct type script way when converted to TS
      if (!Array.isArray(closedTradeData)) {
        setTradePerformance({
          positive: 0,
          negative: 0
        });
        setAverageTradePercent(100);
        setShowClosedTradesSpinner(false);
        return
      }
      const tempClosedTrades = [];
      const tradePerformanceObj = {
        positive: 0,
        negative: 0
      };
      // Endpoint returns null for closed Trades if DB has not be populated yet.
      if (closedTradeData && closedTradeData.length > 0) {
        let profitCalSum = 0;
        closedTradeData.forEach(trade => {
            // Trade dates are in UTC
            const {
              // amount: { N: amount },
              avgPrice: { N: avgPrice },
              closeDate: { S: closeDate },
              closePrice: { N: closePrice },
              // openDate: { S: openDate },
              tokenPair: { S: tokenPair },
              // txnId: { S: txnId },
              // txnSource: { S: txnSource },
              // userId: { S: userId }
            } = trade;
            const profitCalc = ( ( closePrice - avgPrice ) / avgPrice ) * 100;
            profitCalSum = profitCalSum + profitCalc;
            const profit = {N : profitCalc};

            profitCalc > 0 
              ? tradePerformanceObj["positive"] = tradePerformanceObj["positive"] + 1
              : tradePerformanceObj["negative"] = tradePerformanceObj["negative"] + 1;

            const updatedTrades = {
              ...trade,
              profit
            }

            tempClosedTrades.push(updatedTrades);
        });
        setTradePerformance(tradePerformanceObj);
        setClosedTrades(tempClosedTrades);
        setAverageTradePercent(profitCalSum / closedTradeData.length);
        setShowClosedTradesSpinner(false);

      }
      
    });
  };

  const refreshDashboardData = () => {
    fetchDataForTables().then();
    fetchClosedTrades().then();
  }

  useEffect(() => {
    const cookieName = "showFirstTimeLogin";
    const showFirstTimeLogin = Cookies.get(cookieName);

    if (showFirstTimeLogin === null || showFirstTimeLogin === "true" || showFirstTimeLogin === undefined) {
      const sessionShowModal = sessionStorage.getItem("sessionShowModal");
      if (sessionShowModal === null || sessionShowModal === undefined || sessionShowModal === "true"){
        setShowFirstTimeLoginModal(true);
        sessionStorage.setItem("sessionShowModal", "false");
      }

    }

  }, [])

  useEffect(() => {
    if (openPositionData && openPositionData.length > 0){
      let highestProfit = openPositionData[0].profit;
      openPositionData.forEach((position) => {
        highestProfit = Math.max(highestProfit, position.profit);
      });
      setHighestPositionProfit(highestProfit);
    }
  },[openPositionData])

  useEffect(() => {
    if (openPositionData && openPositionData.length > 0 && userBalance) {
      let positionSum = parseFloat(userBalance);

      openPositionData.forEach((position) => {
        positionSum = positionSum + (position.amount * position.currentPrice);
      })
      setPortvolioValue(positionSum);
    } else {
      setPortvolioValue(parseFloat(userBalance))
    }
    
  }, [openPositionData, userBalance])

  useEffect(() => {
    if (user) {
      // console.log("calling use effect")
      fetchDataForTables().then();
      fetchClosedTrades().then();
    }
    // eslint-disable-next-line
  }, [user]);

  if (awsAPIError) {
    return(
      <DashboardLayout page={"Dashboard"}>
        <div className="dashboard-content">
          <div className="dashboard-content-api-error">
            Error fetching data, please try again later.
          </div>
        </div>
      </DashboardLayout>
    )
  }

  if (isMobile) {
    return(
      <DashboardLayout page={"Dashboard"}>
        <div className="dashboard-content">
          <PortfolioMetrics openPositionData={openPositionData} portfolioValue={portfolioValue} tradePerformance={tradePerformance} userBalance={userBalance}/>

          <h1 className="mobile-op-title">Open Positions</h1>
          <div className="mobile-table-updated">
            <p>last updated {date.toString()}</p>
          </div>
          {openPositionData && openPositionData.length > 0 ? (
            <OpenPositionsTable openPositionData={openPositionData} />
          ) : openPositionData && openPositionData.length === 0 ? (
            <TradeInstructions />
          ) : (
            <Spinner containerClass={"spinner-table-container"} />
          )}
          {closedTrades &&
            <Activity closedTrades={closedTrades}/>
          }
        </div>
      </DashboardLayout>
    );
  }

  if (closedTrades === "" && openPositionData && openPositionData.length ===0) {
    return(
      <DashboardLayout page={"Dashboard"}>
        <div className="dashboard-grid-container">

          {openPositionData ? (
            <OpenPositions openPositionData={openPositionData} refreshDashboardData={refreshDashboardData} />
            ) : (
              <OpenPositionsSpinner />
            )
          }
        </div>
      </DashboardLayout>
    );
  }

  return (
    <>
      <DashboardLayout page={"Dashboard"}>
        <div className="dashboard-grid-container">
          <div className="dashboard-grid">

            {/* <div className="dashboard-grid-row-1"> */}

            <div className="grid-row2">

              <PortfolioMetrics 
                openPositionData={openPositionData} 
                portfolioValue={portfolioValue} 
                tradePerformance={tradePerformance} 
                userBalance={userBalance} 
                showTreeMapCardSpinner={showTreeMapCardSpinner}
              />

              {!showOpenPositionTableSpinner ? (
                  <OpenPositions openPositionData={openPositionData} refreshDashboardData={refreshDashboardData} />
                ) : (
                  <OpenPositionsSpinner />
                )
              }
            </div>

            {/* <KeyMetricsController 
              tradePerformance={tradePerformance} 
              averageTradePercent={averageTradePercent} 
              portfolioValue={portfolioValue} 
              user={user}
            /> */}

            <Activity closedTrades={closedTrades} showClosedTradesSpinner={showClosedTradesSpinner} setShowBragCardModal={setShowBragCardModal} setBragCardTrade={setBragCardTrade}/>


            {/* </div> */}



          </div>
        </div>
      </DashboardLayout>
      {showBragCardModal && bragCardTrade && <BragCardModal trade={bragCardTrade} setShowModal={setShowBragCardModal}/>}
      {showFirstTimeLoginModal && <WalkthroughModal handleWalkthroughModalClose={handleWalkthroughModalClose} />}
    </>
  );
};

export default Dashboard;
