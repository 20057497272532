import React, { useState } from "react";

import "./styles/Card.css";
import PieChart from "../nivoCharts/PieChart";
import HoldingsModal from "./modals/HoldingsModal";

import { useTheme } from "../../hooks/useTheme";
import { getColorShadesForPieChart } from "../../helpers/utils";

const HoldingsCard = ({ openPositionData, userBalance}) => {

  const [modalState, setModalState] = useState(false);

  const { isMobile } = useTheme();

  const toggleModal = () => {
    setModalState(!modalState);
  };

  let holdingsArray = [];

  if (openPositionData && openPositionData.length !== 0) {
    holdingsArray = openPositionData.map((token) => ({
      ...token,
      id: token.id.slice(0, -3),
    }));
  } else {
    holdingsArray.push({ id: "USD", totalCost: userBalance, profit: 0.01 });
  }

  // Adding cash to the holding pie
  // sort array on descending value
  holdingsArray.sort((a, b) => b.totalCost - a.totalCost);

  const colors = getColorShadesForPieChart(holdingsArray.length);
  const tokenList = [];
  const chartData = [];
  holdingsArray.forEach((token, index) => {
    tokenList.push(token.id);
    chartData.push({
      id: token.id,
      label: token.id,
      value: token.id === "USD" ? token.totalCost : (token.amount * token.currentPrice),
      color: colors[index],
    });
  });

  const chartSettings = {
    margin: { top: 20, right: 40, bottom: 20, left: 40 },
    arcLinkLabelsSkipAngle: 10,
    arcLinkLabelsDiagonalLength: 10,
    arcLinkLabelsStraightLength: 8,
    startAngle: holdingsArray.length === 1 ? -270 : 0,
    enableArcLabels: false,
    dataColors: true,
    borderColorModifier: 0.2
  };

  return (
    <>
      <div className="card glass-surface-module" onClick={() => !isMobile && toggleModal()}>
        <h1 className="text-header">Allocation</h1>
        <div className="card-info">
          <p className="card-position-value text-base">
            {parseFloat(userBalance).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
            {" USD*"}
            <span className="text-base"> available</span>
          </p>
          <div className="card-position-info">
          </div>
          {/* Nivo Pie Chart goes here */}
          <div className="card-chart-display">
            <PieChart chartData={chartData} chartSettings={chartSettings} />
          </div>
        </div>
      </div>
      {modalState && false && (
        <HoldingsModal
          toggleModal={toggleModal}
          chartData={chartData}
          tokenList={tokenList}
        />
      )}
    </>
  );
};

export default HoldingsCard;
