export const formatClosedPositionsForGrid = (transactionData) => {

    var formatedClosedPositionArray = []
    // Loop through Trade Tokens
    if (Array.isArray(transactionData) && transactionData.length > 0) {
        transactionData.forEach((transaction, index) => {
                // Deconstruct Transaction Object
                const {
                    amount: { N: amount },
                    avgPrice: { N: avgPrice },
                    // closeDate: { S: closeDate },
                    closePrice: { N: closePrice },
                    // openDate: { S: openDate },
                    tokenPair: { S: tokenPair },
                    // txnId: { S: txnId },
                    txnSource: { S: txnSource },
                    // userId: { S: userId }
                    ymd: { S: ymd}
                } = transaction;

                const profit = ( ( closePrice - avgPrice ) / avgPrice ) * 100;
                const dateTokens = ymd.split("-");
                const formattedDate = `${dateTokens[1]}-${dateTokens[2]}-${dateTokens[0]}`;
                // const dateTokens = closeDate.split("T");

                const formatedClosedTransaction = {
                    id: index,
                    tokenId: tokenPair,
                    amount: parseFloat(amount),
                    profit: parseFloat(profit),
                    txnSource: txnSource,
                    closeDate: formattedDate,
                    avgPrice: parseFloat(avgPrice),
                    closePrice: parseFloat(closePrice)
                }
                formatedClosedPositionArray.push(formatedClosedTransaction);
        })
    }

    return(formatedClosedPositionArray)
}