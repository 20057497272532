import React, { useState } from "react";
import "./TradeInstructions.css";

import { useNavigate } from "react-router-dom";
import Button from "../primitives/Button/Button";
import { getCoinBaseTokenPrice, manualTrade } from "../../helpers/api";
import { useAuth } from "../../hooks/useAuth";
import { shuffleArray } from "../../helpers/utils";

const TradeInstructions = () => {

    const {user} = useAuth();

    const navigate = useNavigate();

    const [showSpinner, setShowSpinner] = useState(false);

    const tokenChoices = ["BTC", "ETH", "XRP", "USDT", "SOL", "DOGE", "ADA", "LINK", "AVAX", "XLM", "SUI", "HBAR", "SHIB", "DOT", "LTC", "BCH", "UNI", "NEAR", "AAVE"];
    // $200 per position
    const equityAmount = 200;

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const createTradeObjects = async () => {
        const shuffledArray = shuffleArray(tokenChoices).slice(0,5);

        const tradeObjectsList = await Promise.all(
            shuffledArray.map(async (token) => {
                const [coinBasePriceResponse] = await Promise.all([
                    getCoinBaseTokenPrice(token),
                ]);
    
                if (coinBasePriceResponse.status === 200) {
                    const tokenPrice = parseFloat(coinBasePriceResponse.data.data.amount);
                    return {
                        tokenpair: token + "USD",
                        count: equityAmount / tokenPrice,
                        usdprice: tokenPrice,
                        action: "buy",
                        exchange: "Coinbase",
                        user: user.userId,
                        pin: user.webhookId,
                    };
                }
    
                return null; // If the response is not successful, return null
            })
        );
    
        // Filter out any null values (in case of failed responses)
        return tradeObjectsList.filter((tradeData) => tradeData !== null);
    };

    // Purchase $1,000 total of top 5 tokens
    const handleQuickStart = async () => {

        try {
            const tradeObjects = await createTradeObjects();
    
            if (tradeObjects.length > 0) {
                setShowSpinner(true);
                // Something here is over-whelming the server
                // const results =  await Promise.all(
                //     tradeObjects.map((tradeObject) => manualTrade(tradeObject))
                // );

                for (const tradeObject of tradeObjects) {
                    const response = await manualTrade(tradeObject);
                    console.log("API: Response: ", response);
                    await delay(1000);
                }

                setShowSpinner(false);
                window.location.reload(false);
            } else {
                console.log("No Trades were created");
            }
        } catch (error) {
            console.error("An error occured: ", error);
        }
       
    }

    return(
        <div className="ti-trade-config-wrapper">

            <div className="ti-trade-config-container">
                <div className="ti-trade-config-info-container glass-surface-module">
                    <h1 className="text-header">Manually Enter Trades</h1>
                    <p className="text-base">Paper Trade over 200 cryptocurrencies quickly and easily with our manual trading interface.</p>
                    <div className="ti-trade-config-info-button">
                        <Button kind="pill" onClick={() => navigate("/manualtrades")}>MANUAL</Button>
                    </div>
                </div>

                <div className="ti-trade-config-info-container glass-surface">
                    <h1 className="text-header">Feeling Lucky?</h1>
                    <p className="text-base">Click here to create a starter portfolio from a random selection of popular cryptocurrencies.</p>
                    <div className="ti-trade-config-info-button">
                        <Button 
                            kind="pill" 
                            onClick={handleQuickStart}
                            showSpinner={showSpinner}
                            loadingText="Buying Tokens..."
                        >
                            QUICK START
                        </Button>
                    </div>
                </div>

                <div className="ti-trade-config-info-container glass-surface">
                    <h1 className="text-header">Automate Your Trades</h1>
                    <p className="text-base"> Advanced users can send your buy/sell signals from another platform like Tradingview.</p>
                    <div className="ti-trade-config-info-button">
                        <Button kind="pill" onClick={() => navigate("/webhook")}>WEBHOOK</Button>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default TradeInstructions;