import React from "react";
import "./styles/PageTwo.css";

import Box from "../assets/box.png";
import Calendar from "../assets/calendar.png";
import Statistics from "../assets/statistics.png";

const PageTwo = () => {
  return (
    <section className="page-two landing-page-padding">
      <div className="page-wrapper">
        <div className="page-two-header">
          <h1 className="page-two-title text-heading text-dark-blue">
            A sandbox trading environment
          </h1>
          <h2 className="page-two-sub-title">
            Built for humans, but equally kind to bots.
          </h2>
        </div>
        <div className="page-two-card-container">
          <div className="page-two-card">
            <div className="card-header">
              <img src={Calendar} alt="" />
            </div>
            <h2>Getting started is easy</h2>
            <p>
              No cash deposit necessary. You get $10,000 in mock USD to immediately begin simulated buying and selling.
            </p>
          </div>

          <div className="page-two-card">
            <div className="card-header">
              <img src={Statistics} alt="" />
            </div>
            <h2>Adjust your strategy over time</h2>
            <p>
              Track your daily profit & loss while testing your luck across literally hundreds of cryptocurrency tokens available.
            </p>
          </div>

          <div className="page-two-card">
            <div className="card-header">
              <img src={Box} alt="" />
            </div>
            <h2>Connect to our API (optional)</h2>
            <p>
              Send us trading signals from another tool like
              Tradingview, for example. We are built to receive "webhooks" via API.
            </p>
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default PageTwo;
