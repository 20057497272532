import React from "react";

import "./DashboardLinks.css";

import { NavLink } from "react-router-dom";

import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import DraftsIcon from "@mui/icons-material/Drafts";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WorkIcon from "@mui/icons-material/Work";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';

import { useAuth } from "../../hooks/useAuth";
import { useTheme } from "../../hooks/useTheme";

import Button from "../primitives/Button/Button";
import { MuiSwitchDarkMode } from "../switch/MuiSwitch";
import { DiscordIcon } from "../CustomIcons/DiscordIcon";

const DashboardLinks = ({ isSideBarExpanded, ...props }) => {

  const { logout } = useAuth();
  const { isMobile } = useTheme();

  const handleExternalDocsLink = () => {
    const url = "https://docs.chart.observer/knowledge-base/";
    window.open(url, "_blank", "noopener,noreferrer");
  }

  const handleDiscord = () => {
    window.open(
      "https://discord.gg/5M4CbvdMVd",
      '_blank'
    );
  };

  return (
    <div className="dashboard-links">

      {isMobile && <MuiSwitchDarkMode />}
      {/* Side Bar Activity */}
      <div className="dashboard-links-section">
        {isSideBarExpanded && (
          <h2 className="dashboard-links-section-title text-base">ACTIVITY</h2>
        )}

        {/* Open Positions */}
        <NavLink to="/dashboard" className="dashboard-links-section-item">
          <div className="dashboard-links-icon-container">
            <WorkIcon className="dashboard-links-icon" />
          </div>
          {isSideBarExpanded && <span className="text-base">Dashboard</span>}
        </NavLink>

        {/* Feed */}
        {/* <NavLink to="/feed" className="dashboard-links-section-item">
          <div className="dashboard-links-icon-container">
            <NewspaperIcon className="dashboard-links-icon" />
          </div>
          {isSideBarExpanded && <span className="text-base">Feed</span>}
        </NavLink> */}

        {/* Manual Trade */}
        <NavLink to="/manualtrades" className="dashboard-links-section-item">
          <div className="dashboard-links-icon-container">
            <CandlestickChartIcon className="dashboard-links-icon" />
          </div>
          {isSideBarExpanded && <span className="text-base">Manual Trade</span>}
        </NavLink>

        {/* Trade History */}
        <NavLink to="/history" className="dashboard-links-section-item">
          <div className="dashboard-links-icon-container">
            <CalendarMonthIcon className="dashboard-links-icon" />
          </div>
          {isSideBarExpanded && <span className="text-base">Trade History</span>}
        </NavLink>
        {/* Leader Board */}
        <NavLink to="/leaderboard" className="dashboard-links-section-item">
          <div className="dashboard-links-icon-container">
            <LeaderboardIcon className="dashboard-links-icon" />
          </div>
          {isSideBarExpanded && <span className="text-base">Leader Board</span>}
        </NavLink>
        {/* Strategies */}
        {/* <NavLink to="/strategies" className="dashboard-links-section-item">
          <div className="dashboard-links-icon-container">
            <Leaderboard className="dashboard-links-icon" />
          </div>
          {isSideBarExpanded && <span className="text-base">My Strategies</span>}
        </NavLink> */}
      </div>
      {/* Sidebar Settings */}
      <div className="dashboard-links-section">
        {isSideBarExpanded && (
          <h2 className="dashboard-links-section-title text-base">SETTINGS</h2>
        )}

        {/* Integrations */}
        <NavLink to="/integrations" className="dashboard-links-section-item">
          <div className="dashboard-links-icon-container">
            <SettingsIcon className="dashboard-links-icon" />
          </div>
          {isSideBarExpanded && <span className="text-base">Integrations</span>}
        </NavLink>

        {/* Webhook URL Instructions */}

        {/* Account Info */}
        <NavLink to="/account" className="dashboard-links-section-item">
          <div className="dashboard-links-icon-container">
            <PersonIcon className="dashboard-links-icon" />
          </div>
          {isSideBarExpanded && <span className="text-base">Account Info</span>}
        </NavLink>
      </div>
      {/* Help */}
      <div className="dashboard-links-section">
        {isSideBarExpanded && (
          <span className="dashboard-links-section-title text-base">Help</span>
        )}
        {/* Knowledge Base */}
        
        <div className="dashboard-links-section-item external-link" onClick={handleExternalDocsLink}>
          <div className="dashboard-links-icon-container">
            <LibraryBooksIcon className="dashboard-links-icon" />
          </div>
          {isSideBarExpanded && <span className="text-base">Knowledge Base</span>}
        </div>
        {/* Contact Us */}

        <NavLink to="/contact" className="dashboard-links-section-item">
          <div className="dashboard-links-icon-container">
            <DraftsIcon className="dashboard-links-icon" />
          </div>
          {isSideBarExpanded && <span className="text-base">Contact Support</span>}
        </NavLink>

        {/* Discord Link */}
        {isMobile &&
        <div className="dashboard-links-section-item external-link" onClick={handleDiscord}>
          <div className="dashboard-links-icon-container">
            <DiscordIcon />
          </div>
          {isSideBarExpanded && <span className="text-base">Discord Server</span>}
        </div>
        }
      </div>

      <div className="dashboard-links-signout-section">
        <Button kind="pill" onClick={() => logout()}>Sign Out</Button>
      </div>
    </div>
  );
};

export default DashboardLinks;
