import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import "./Leaderboard.css";
import { LeaderboardCard } from "../../components/LeaderboardCard/LeaderboardCard";
import { LeaderboardTable } from "../../components/LeaderboardTable/LeaderboardTable";
import { ButtonGroup } from "../../components/ButtonGoup/ButtonGroup";

import { getClosedTradesLeaderboard} from "../../helpers/api";
import Spinner from "../../components/loadingAnimations/Spinner";
import { usePageTracking } from "../../hooks/usePageTracking";

export const Leaderboard = () => {

    const timeSeriesOptions = ["Day", "Week", "Month"];

    const [timeSeries, setTimeSeries] = useState(timeSeriesOptions[0]);
    const [topTraders, setTopTraders] = useState(null);
    const [leaderboardData, setLeaderboardData] = useState(null);
    const handleTimeSeriesSelection = (option) => {
        setTimeSeries(option);
    } 

    const timeSeriesMap = {
        "Day"   : 1,
        "Week"  : 7,
        "Month" : 30
    }

    usePageTracking();
    
    const previousTimeSeries = useRef(timeSeries);

    const fetchLeaderBoardData = async () => {
        return Promise.all([
            getClosedTradesLeaderboard()
        ]).then(([response]) => {
            if (response.status === 200) {
                const data = response.data;
                try {
                    const topTraders = data["7"].topTraders;
                    if ( topTraders.length > 0 ){
                        setTopTraders(topTraders    );
                    }
                    const leaderBoadData = data["7"].leaderBoard;
                    if ( leaderBoadData.length > 0 ) {
                        setLeaderboardData(leaderBoadData);
                    }
                } catch (error) {
                    console.error("Bad Leaderboard Object");
                }
            }
        });
    }

    useEffect(() => {
        fetchLeaderBoardData();
    }, []);

    return(
        <DashboardLayout page={"Leader Board"}>
            <div className="leaderboard-content">

                <div className="leaderboard-header">
                    <h1 className="text-header" >Top Traders</h1>
                    {/* <ButtonGroup options={timeSeriesOptions} setCurrentButton={handleTimeSeriesSelection}/> */}
                </div>


                {topTraders ? (
                    <div className="leaderboard-top">
                        {topTraders && topTraders.map((traderData, index) => (
                            <LeaderboardCard key={traderData.username} cardInfo={traderData} index={index} />
                        ))}
                    </div>
                ) : (
                    <div className="glass-surface-module leaderboard-spinner">
                        <Spinner spinnerColor="grey"/>
                    </div>
                )}

                <LeaderboardTable leaderBoadData={leaderboardData}/>

            </div>
        </DashboardLayout>
    );

}

