import React, { useEffect, useRef, useState } from "react";

import "./AccountInfo.css";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";

import { useAuth } from "../../hooks/useAuth";
import { usePageTracking } from "../../hooks/usePageTracking";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Switch } from "@mui/material";

import Button from "../../components/primitives/Button/Button";
import Popover from "../../components/Popover/Popover";

import { getPublicProfile, postPublicProfile } from "../../helpers/api";
import { useNavigate } from "react-router-dom";

export const AccountPage = () => {
  const { user, logout } = useAuth();

  const [userInfoObject, setUserInfoObject] = useState(null);
  
  usePageTracking();

  const getUserAccountInfo = async (username) => {
      const response = await getPublicProfile(username);

      if (response.status === 200) {
          const data = response.data.data;
          data["pin"] = user.webhookId;
          setUserInfoObject(data);
          // console.log(data);
      }
  }

  // Tapping into state set function to ensure we are using the most up to date state object when making API call.
  const updateUserInfoState = (key, value) => {
    setUserInfoObject((previousSate) => {

      const newUserInfoObject = {...previousSate, [key] : value}

      updatePublicProfile(newUserInfoObject);
      // console.log(newUserInfoObject);

      return newUserInfoObject;
    });
    
  }

  const updatePublicProfile = async (newUserInfoObject) => {
    // console.log(newUserInfoObject);
    return Promise.all([
      postPublicProfile(newUserInfoObject)
      
    ]).then(([response]) => {
      if (response.status === 200) {
        // console.log(response);
        return response;
      }
    })
  }
  
  useEffect(() => {
    if (user.userName) {
      getUserAccountInfo(user.userName);
    }
  }, [])
  
  return (
    <DashboardLayout page={"Account Information"}>
      <div className="account-info-content">
        <div className="account-info-content-compact">
          {/* Hero Banner */}
          <UserName user={user} logout={logout}/>

          {userInfoObject && 
          <>
            <TradingViewLink user={user} userInfoObject={userInfoObject} updateUserInfoState={updateUserInfoState}/>

            <AccountDescription user={user} userInfoObject={userInfoObject} updateUserInfoState={updateUserInfoState}/>
          
            <SocailMediaLinks user={user} userInfoObject={userInfoObject} updateUserInfoState={updateUserInfoState}/>
  
            <AccountPreferences userInfoObject={userInfoObject} updateUserInfoState={updateUserInfoState} />
          </>
          }


          <AccountInfo user={user} />
        </div>
      </div>
    </DashboardLayout>
  );
};

const UserName = ({user, logout}) => {
  const username = user.userName ? user.userName : "";
  // const firstName = user.firstName ? user.firstName : "";
  // const lastName = user.lastName ? user.lastName : "";
  const dateJoined = user.dateJoined ? user.dateJoined.split("T")[0] : "";
  // const usdDeposits = user.usdDeposits ? user.usdDeposits : "";
  const usdBalance = user.walletBalance ? user.walletBalance : "";
  const userEmail = user.userEmail ? user.userEmail : "";

  // const subscriptionType = "Trial";
  const navigate = useNavigate();

  const handlePublicProfileNav = () => {
    if (user) {
      navigate(`/profile/${username}`)
    }
  }

  return(
    <div className="glass-surface-module">
      <div className="user-hero-banner">
          <div className="user-hero-info-wrapper">
            <AccountCircleIcon sx={{fontSize: 68}} className="user-hero-icon"/>
            <div className="user-hero-info-column">
              <div className="user-hero-info-user-container">
                <h1 className="text-hero text-color-primary">{username}</h1>
              </div>
            </div>
          </div>
          {/* Removing until we have functionality for this. */}
          {/* <Button kind="edit-profile"><span className="text-base">Edit profile photo</span></Button> */}
          {/* <Button kind="edit-profile" hover={true} onClick={() => logout()}>Sign Out</Button> */}
          <Button kind="edit-profile" type="button" onClick={handlePublicProfileNav}>View Your Profile</Button>
      </div>
    </div>
  )
}

const TradingViewLink = ({user, userInfoObject, updateUserInfoState}) => {

  const [tradingViewProfile, setTradingViewProfile] = useState('');
  const [isSaveChangesActive, setIsSaveChangesActive] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const intialTradingViewProfile = useRef('');

  const handleChange = (event) => {
    setTradingViewProfile(event.target.value);
  }

  const handleSaveTradingView = async () => {
    if (isSaveChangesActive) {

      setShowSpinner(true);

      updateUserInfoState("tradingviewURL", tradingViewProfile);
  
      setShowSpinner(false);
      setIsSaveChangesActive(false);
    }
  }

  useEffect(() => {
    if (user && "tradingviewURL" in userInfoObject){
      intialTradingViewProfile.current = userInfoObject.tradingviewURL;
      setTradingViewProfile(userInfoObject.tradingviewURL);
    }
  }, []);

  useEffect(() => {
    if (tradingViewProfile !== intialTradingViewProfile.current) {
      if (tradingViewProfile.length < 100) {
        setIsSaveChangesActive(true);
      } else {
        setIsSaveChangesActive(false);
      }

    } else {
      setIsSaveChangesActive(false);
    }
  }, [tradingViewProfile]);

  return(
    <div className="glass-surface-module account-tradingview">
      <h1 className="text-base text-color-primary">TradingView</h1>
      <div className="account-tradingview-container">
        <input 
          id="tradingview-profile-input"
          value={tradingViewProfile ?? ""}
          onChange={handleChange}
          placeholder={tradingViewProfile || "https://www.tradingview.com/u/myprofile/"}
          className="account-tradingview-profile-input"
        />
        <Button 
          kind={`edit-profile${isSaveChangesActive ? "" : "-disabled"}`} 
          hover={false}
          showSpinner={showSpinner}
          onClick={handleSaveTradingView}
        >
          Add
        </Button>
      </div>
    </div>
  );
}

const AccountDescription = ({user, userInfoObject, updateUserInfoState}) => {

  const [aboutMe, setAboutMe] = useState('');
  const [isSaveChangesActive, setIsSaveChangesActive] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const initialAboutMe = useRef('');

  const handleChange = (event) => {
    setAboutMe(event.target.value);
  }

  const handleSaveDescription = async () => {
    // API Call to save new user description will go here.
    setShowSpinner(true);

    updateUserInfoState("description", aboutMe);

    setShowSpinner(false);
    setIsSaveChangesActive(false);
  }

  useEffect(() => {
    if ("description" in userInfoObject) {
      initialAboutMe.current = userInfoObject.description;
      setAboutMe(userInfoObject.description);
    }
  }, []);

  useEffect(() => {
    if (aboutMe !== initialAboutMe.current) {
      if (aboutMe.length < 500) {
        setIsSaveChangesActive(true);
      } else {
        setIsSaveChangesActive(false);
      }
    } else {
      setIsSaveChangesActive(false);
    }
  }, [aboutMe]);

  return(
    <div className="account-description-container glass-surface-module">
      <div className="acount-description-title">
        <h1 className="text-base text-color-primary">About Me</h1>
        <Popover
          trigger={<InfoRoundedIcon sx={{fontSize: 20}} className="account-description-info-icon"/>}
          content={<p>This is a great place to share your trading system, if you have one.  Talk about your favorite chart indicators or tools that help you make winning trades.  You may also share an approved URL to your website.  Use the contact form to request approval first, otherwise it may get deleted by our admins. No hate speech, spamming, off-topic, or offensive content. See our Terms of Service for more guidance.</p>}
          position="right"
        />
      </div>
      <textarea
        id="about-me"
        value={aboutMe ?? ""}
        onChange={handleChange}
        placeholder={aboutMe || "Tell us about yourself..."}
        className="account-description-textarea"
      />
      <div className="account-description-button-container">
        <Button 
          kind={`edit-profile${isSaveChangesActive ? "" : "-disabled"}`} 
          hover={false}
          showSpinner={showSpinner}
          onClick={handleSaveDescription}
        >
          Save changes
        </Button>
      </div>
    </div>
  );
}

const SocailMediaLinks = ({user, userInfoObject, updateUserInfoState}) => {

  const onSubmit = async (urlObject) => {

    updateUserInfoState(urlObject.field + "URL", urlObject.value);

    return {spinnerState: false};

  }

  if (!userInfoObject) {
    return
  }

  return(
    <div className="glass-surface-module account-tradingview">
      <h1 className="text-base text-color-primary">Other Socials</h1>
      <SocialLinkInput initialValue={userInfoObject.twitterURL} field={"twitter"} placeholder={"https://www.x.com/me"} onSubmit={onSubmit} label={"X (formerly Twitter)"} />
      <SocialLinkInput initialValue={userInfoObject.youtubeURL} field={"youtube"} placeholder={"https://www.youtube.com/@me"} onSubmit={onSubmit} label={"Youtube"}/>
      <SocialLinkInput initialValue={userInfoObject.instagramURL} field={"instagram"} placeholder={"https://www.instagram.com/me"} onSubmit={onSubmit} label={"Instagram"}/>
      {/* <SocialLinkInput initialValue={userInfoObject.farcasterURL} field={"farcaster"} placeholder={"https://www.instagram.com/me"} onSubmit={onSubmit} />
      <SocialLinkInput initialValue={userInfoObject.telegramURL} field={"telegram"} placeholder={"https://www.t.me/me"} onSubmit={onSubmit} /> */}
    </div>
  );
}

const AccountPreferences = ({userInfoObject, updateUserInfoState}) => {

  const [switchStates, setSwitchStates] = useState({
    showPortfolio: true,
    copyTradable: false
  });

  const handleSwitchChagne = (event) => {
    setSwitchStates((previousSate) => {
      const newSwitchState = {...previousSate, [event.target.name] : event.target.checked};

      // Lambda currently expects string for bool
      updateUserInfoState(event.target.name, event.target.checked ? "true" : "false");

      return newSwitchState;
    });
  }

  useEffect(() => {
    if (userInfoObject) {
      // Lambda is casting bool's as strings, so will need to do this. Also may have one and not the other, so need to check keys separately for safety
      if ("copyTradable" in userInfoObject) {
        setSwitchStates({
          ...switchStates,
          copyTradable: (userInfoObject.copyTrading === "true") ? true : false
        })
      }
  
      if ("showPortfolio" in userInfoObject) {
        setSwitchStates({
          ...switchStates,
          showPortfolio: (userInfoObject.showPortfolio === "true") ? true : false
        })
      }
    }
  }, []);

  return(
    <div className="glass-surface-module account-preferences">
      <h1 className="text-base text-color-primary">Account Preferences</h1>

      <div className="account-preference-container">
        <h1 className="text-base text-color-secondary">Show My Trades</h1>
        <p className="text-base text-color-primary">
        Your simulated trading activity is visible to anyone who visits your public profile page.  If you prefer to hide your portfolio from others, switch this setting off.
        </p>
        <div className="account-preference-switch-container">
          <Switch checked={switchStates.showPortfolio} onChange={handleSwitchChagne} name="showPortfolio"/>
          <p className="text-base text-color-primary">{switchStates.showPortfolio ? "Public" : "Private"}</p>
        </div>
      </div>

      <div className="account-preference-container">
        <h1 className="text-base text-color-secondary">Allow Copy Trading</h1>
        <p className="text-base text-color-primary">
        Users can choose to be alerted when you trade.  People will be blocked from subscribing to your activity unless you turn this on.  We will alert you when this feature is available.
        </p>
        <div className="account-preference-switch-container">
          <Switch checked={switchStates.copyTradable} onChange={handleSwitchChagne} name="copyTradable" disabled={true}/>
          <p className="text-base text-color-secondary">coming soon</p>
        </div>
      </div>

    </div>
  );
}

const AccountInfo = ({user}) => {

  const username = user.userName ? user.userName : "";
  // const firstName = user.firstName ? user.firstName : "";
  // const lastName = user.lastName ? user.lastName : "";
  const dateJoined = user.dateJoined ? user.dateJoined.split("T")[0] : "";
  // const usdDeposits = user.usdDeposits ? user.usdDeposits : "";
  const usdBalance = user.walletBalance ? user.walletBalance : "";
  const userEmail = user.userEmail ? user.userEmail : "";

  return(
    <div className="account-info-container glass-surface-module">
      <h1 className="text-base text-color-primary">Account Info</h1>
      <div className="account-info-grid">

        <div className="account-info-grid-item">
          <h1 className="text-base text-color-secondary">Username</h1>
          <h1 className="text-base text-color-primary">{username}</h1>
        </div>

        <div className="account-info-grid-item">
          <h1 className="text-base text-color-secondary">Email</h1>
          <h1 className="text-base text-color-primary">{userEmail}</h1>
        </div>

        <div className="account-info-grid-item">
          <h1 className="text-base text-color-secondary">Date Joined</h1>
          <h1 className="text-base text-color-primary">{dateJoined}</h1>
        </div>

        <div className="account-info-grid-item">
          <h1 className="text-base text-color-secondary">Balance</h1>
          <h1 className="text-base text-color-primary">{Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD'}).format(usdBalance)}</h1>
        </div>

      </div>

    </div>
  );
}

const SocialLinkInput = ({initialValue, placeholder, onSubmit, field, label}) => {

  const [socialMediaLink, setSocialMediaLink] = useState('');
  const [isSaveChangesActive, setIsSaveChangesActive] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const initialSocialMediaLink = useRef('');

  const regexObject = {
    "twitter" : /^https:\/\/www\.x\.com\/[\w-]+$/,
    "youtube" : /^https:\/\/www\.youtube\.com\/@[\w-]+$/,
    "instagram" : /^https:\/\/www\.instagram\.com\/[\w-]+$/
  }

  const handleChange = (event) => {
    setSocialMediaLink(event.target.value);
  }

  const handleAdd = () => {
    // Use passed onSubmitFunction
    if (isSaveChangesActive) {
      setShowSpinner(true);

      const spinnerStateResponseObject = onSubmit({
        field: field,
        value: socialMediaLink
      })

      setShowSpinner(spinnerStateResponseObject.spinnerState);
      setIsSaveChangesActive(false);
    }
  }

  useEffect(() => {
    initialSocialMediaLink.current = initialValue;
    setSocialMediaLink(initialValue);

  }, []);

  useEffect(() => {
    if (socialMediaLink !== initialSocialMediaLink.current) {
      if (regexObject[field].test(socialMediaLink) || socialMediaLink === "") {
        if (socialMediaLink.length < 100) {
          setIsSaveChangesActive(true);
        } else {
          setIsSaveChangesActive(false);
        }
      } else {
        setIsSaveChangesActive(false);
      }
    } else {
      setIsSaveChangesActive(false);
    }
  }, [socialMediaLink]);

  return(
    <>
      <p className="text-small">{label}</p>
      <div className="account-tradingview-container">
          <input 
            id="tradingview-profile-input"
            value={socialMediaLink ?? ''}
            onChange={handleChange}
            placeholder={initialValue || placeholder}
            className="account-tradingview-profile-input"
          />
          <Button 
            kind={`edit-profile${isSaveChangesActive ? "" : "-disabled"}`} 
            hover={false}
            showSpinner={showSpinner}
            onClick={handleAdd}
          >
            Add
          </Button>
        </div>
    </>
  )
} 