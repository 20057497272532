import { CheckBox } from "@mui/icons-material";
import Spinner from "../../components/loadingAnimations/Spinner"
import "./ProfileHoldingsChart.css";

import TreeMapChart from "../../components/nivoCharts/TreeMapChart";
import { Switch } from "@mui/material";

export const ProfileHoldingsChart = ({holdingsData, showHoldingsChartSpinner, includeUSD, setIncludeUSD}) => {

    const chartSettings = {
        margin: { top: 10, right: 0, bottom: 40, left: 0 },
        smallIcons: false
    }

    if (showHoldingsChartSpinner) {
        return(
            <div className="glass-surface-module">
                <h1 className="text-header">Current Portfolio</h1>

                <Spinner spinnerColor="grey" />
            </div>
        )
    }

    if (holdingsData === null) {
        return(
            <div className="glass-surface-module">
                <h1 className="text-header">Current Portfolio</h1>
                <p className="text-base">User currently does not have any open positions.</p>
            </div>
        )
    }

    const handleChange = (event) => {
        setIncludeUSD(event.target.checked);
    }

    return(

        <div className="profile-page-current-portfolio glass-surface-module">
            <div className="profile-page-current-portfolio-header-container">
                <h1 className="text-header">Current Portfolio</h1>
                <div className="profile-holdings-switch-container">
                    <p className="text-small">Include USD</p>
                    <Switch checked={includeUSD} onChange={handleChange} name="includeUSD"/>
                </div>
            </div>
            <TreeMapChart chartData={holdingsData} chartSettings={chartSettings}/>

        </div>

    );
}