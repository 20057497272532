import React from "react";
import "./Button.css";
import Spinner from "../../loadingAnimations/Spinner";

/**
 *
 * @param kind - 'primary', 'secondary', 'tertiary', 'plan', 'pill', trade-buy, trade-sell, edit-profile
 * @param hover - 'true', 'false', determines if text changes color on hover
 * @param disabled = bool, gray's out button if disabled - need to create additional class for type of button
 * @returns A button component
 */
const Button = ({ kind = "primary", type = "button", hover = false, disabled = false, showSpinner = false, loadingText = "", ...props }) => {
  return (
    <>
      {!showSpinner ? (
        <button type={type} className={`button button--${disabled ? kind+'disabled' : kind} ${hover ? 'button--hover' : ''}`} {...props}>
          {props.children}
        </button>
      ) : (
        <button type={type} className={`button button--${disabled ? kind+'disabled' : kind} ${hover ? 'button--hover' : ''}`} {...props}>
          <Spinner  spinnerSize="spinner-small"/>
          {loadingText !== "" ? loadingText : ""}
        </button>
      )}
    </>
  );
};

export default Button;
