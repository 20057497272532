import DELISTED_COINS from "../components/dropDown/DELISTED_COINS"

export const removeUnsupportedTokens = (transactionData, coinbaseData) => {
    // Clean up open transaction data by removing any delisted or unsupported tokens - Possibly move this to backend?
    const supportedTransactions = [];
    if (coinbaseData && transactionData) {
      
      const supportedTokens = getSupportedTokens(Object.keys(coinbaseData.data.rates));
      const setSupportedTokens = new Set(supportedTokens);

      transactionData.forEach(trade => {
        const tokenName = trade.tokenPair.S.slice(0,-3);
        if (setSupportedTokens.has(tokenName)) {
          supportedTransactions.push(trade);
        }
      });

    }

    return supportedTransactions;
}

export const removeUnsupportedTokensForDropDown = (coinbaseData, dropDownTokens) => {

  const setSupportedTokens = new Set(getSupportedTokens(Object.keys(coinbaseData.data.rates)));

  const returnTokens = dropDownTokens.filter( value => setSupportedTokens.has(value));
  return returnTokens;

}

// Remove Delisted coins from Coinbase data
const getSupportedTokens = (coinbaseTokens) => {
  const setDelistedCoins = new Set(DELISTED_COINS); // Converting to Set for O(1) lookup
  const supportedTokens = coinbaseTokens.filter( item => !setDelistedCoins.has(item));
  return supportedTokens;
}