import React from "react";

import "./LandingPage.css";
import LandingPageNavigation from "../../components/LandingPageNavigation/LandingPageNavigation";
import PageOne from "./sections/PageOne";
import PageTwo from "./sections/PageTwo";
import PageThree from "./sections/PageThree";
import PageFour from "./sections/PageFour";
import PageFive from "./sections/PageFive";
import Footer from "./sections/Footer";
import { usePageTracking } from "../../hooks/usePageTracking";

const LandingPage = () => {
  
  // Page Title Setting for GA4
  usePageTracking();

  return (
    <div className="landing-page">
      <LandingPageNavigation />
      <PageOne />
      <PageTwo />

      <PageFour />
      <PageFive />
      <Footer />
    </div>
  );
};

export default LandingPage;
