import React, { useEffect, useState, forwardRef } from "react";
import "./ProfilePage.css";
import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { Tooltip } from "@mui/material";
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

import { SocialIcon } from 'react-social-icons';

import { getClosedTradesByUserId, getCoinBaseData, getOpenPositionsByUserId, getPublicProfile, getUserBalance } from "../../helpers/api";
import { getColorShadeFromProfit, shuffleArray } from "../../helpers/utils";
import { removeUnsupportedTokens } from "../../helpers/removeUnsupportedTokens";
import { formatDataForGrid } from "../../helpers/formatOpenPostions";
import { formatClosedPositionsForGrid } from "../../helpers/formatClosedPositions";

import { useParams } from "react-router-dom";
import { RecentClosedTrades } from "./RecentClosedTrades";
import { ProfileHoldingsChart } from "./ProfileHoldingsChart";

import { usePageTracking } from "../../hooks/usePageTracking";

// Wrap ShareIcon to forward ref - required when passing a custom component to a MUI Tooltip
const ForwardedShareIcon = forwardRef((props, ref) => (
    <ContentCopyRoundedIcon {...props} ref={ref} />
));

export const ProfilePage = () => {

    const {username} = useParams();

    const [recentClosedTrades, setRecentClsoedTrades] = useState(null);
    const [openPositionData, setOpenPositionData] = useState(null);
    const [holdingsData, setHoldingsData] = useState(null);

    const [usernameState, setUsernameState] = useState(null);
    const [userInfoObject, setUserInfoObject] = useState(null)
    const [userId, setUserId] = useState(null);
    const [userBalance, setUserBalance] = useState(null);

    const [showClosedTradesSpinner, setShowClosedTradesSpinner] = useState(true);
    const [showHoldingsChartSpinner, setShowHoldingsChartSpinner] = useState(true);

    const [showPortfolio, setShowPortfolio] = useState(null);

    const [coinImageDataKeys, setCoinImageDataKeys] = useState(null);

    const [toolTipText, setToolTipText] = useState("Copy Profile Link")

    // Controls if USD should be shown in TreeMap
    const [includeUSD, setIncludeUSD] = useState(true);

    usePageTracking();

    const fetchDataForProfilePage = async () => {
        return Promise.all([
            getClosedTradesByUserId(userId, 10),
            getOpenPositionsByUserId(userId),
            getCoinBaseData(),
            getUserBalance(userId)
        ]).then(([closedTradeData, openPositionData, coinbaseResponse, userBalance]) => {

            if (userBalance) {
                setUserBalance(userBalance)
            }
            // Check Return Objects for closed Trades
            if (Array.isArray(closedTradeData) && closedTradeData.length > 0) {
                // console.log(closedTradeData);
                // Use This data for avg profit calc
                const recentClosedTrades = formatClosedPositionsForGrid(closedTradeData);
                // console.log(recentClosedTrades);
                setRecentClsoedTrades(recentClosedTrades);
            }
            setShowClosedTradesSpinner(false);

            // Formatting Data for Current Holdings
            if (Array.isArray(openPositionData.data) && openPositionData.data.length > 0 && coinbaseResponse.status === 200) {
                const supportedOpenTransacitonData = removeUnsupportedTokens(openPositionData.data, coinbaseResponse.data);

                const openPositionDataWithCoinbasePrice = formatDataForGrid(supportedOpenTransacitonData, coinbaseResponse.data);

                setOpenPositionData(openPositionDataWithCoinbasePrice);   
            } else {
                setShowHoldingsChartSpinner(false);
            }
        })
    }

    const OpenTradingViewProfile = () => {

        window.open(userInfoObject.tradingviewURL, "_blank", "noopener,noreferrer");
    }

    const buildTreeMapChartData = () => {
        const treeMapChartdata = {
            "name" : "Positions",
            "color" : "hsl(0, 0%, 100%)",
            "children" : []
        }

        if (includeUSD && userBalance) treeMapChartdata.children.push(
            {
                "name" : "USD",
                "loc" : userBalance,
                "color" : "hsla(226, 90%, 75%, 0.5)",
                "profit" : 0,
                "border" : "hsla(226, 90%, 75%, 1)",
                "img" : false
            }
        )

        const profits = openPositionData.map(obj => Math.abs(obj.profit));
        const profitMin = Math.min(...profits);

        const profitMax = Math.max(...profits);

        openPositionData.forEach((token) => {

            const [background, borderColor] = getColorShadeFromProfit(token.profit, profitMin, profitMax);

            treeMapChartdata.children.push(
                {
                    "name" : token.id,
                    "loc" : (token.currentPrice * token.amount),
                    "profit" : (token.profit),
                    "color" : background,
                    "border" : borderColor,
                    "img" : coinImageDataKeys && coinImageDataKeys.includes(token.id.slice(0,-3)) ? `/cc_logos/${token.id.slice(0,-3)}.png` : false
                }
            )
        })

        treeMapChartdata.children.sort((a, b) => b.loc - a.loc);

        setHoldingsData(treeMapChartdata);
        setShowHoldingsChartSpinner(false);
    }


    const getUserAccountInfo = async (username) => {
        const response = await getPublicProfile(username);
        console.log(response);

        if (response.status === 200) {
            const data = response.data.data;
            setUserInfoObject(data);
            if (data.showPortfolio === "true") {
                setUserId(data.userId);
                setShowPortfolio(true);
            } else {
                setShowPortfolio(false);
            }
        }

    }

    const handleCopyClick = async () => {
        try {
            if (usernameState) {
                await navigator.clipboard.writeText(`https://chart.observer/profile/${usernameState}`);
                setToolTipText("Copied!");
            }
        } catch (error) {
            // Add error handling?
        }
    }

    const handleToolTipClose = () => {
        setToolTipText("Copy Profile Link");
    }

    const getCoinImageData = async () => {
        try {
            const response = await fetch("/cc_logos/token_cc_url.json");
            const data = await response.json();
            setCoinImageDataKeys(Object.keys(data));
        } catch (error) {
            console.error("Error Loading ImageJSON: ", error);
        }
    }

    useEffect(() => {
        if (username) {
            setUsernameState(username);
            getUserAccountInfo(username);
        }
    },[username]);

    useEffect(() => {
        if (openPositionData) {
            buildTreeMapChartData();
        }
    }, [openPositionData, includeUSD])

    useEffect(() => {  
        const loadData = async () => {
            await getCoinImageData();  // Await getCoinImageData before continuing
            if (userId) {
                fetchDataForProfilePage();
            }
        };
        loadData();  // Call the async function
    },[userId]);

    return(
        <DashboardLayout page={"Profile"}>
            <div className="profile-page-content">
                <div className="profile-page-username-container">

                    <AccountCircleIcon sx={{fontSize: 100}} className="user-hero-icon"/>

                    <div className="profile-page-user-info">
                        <div className="profile-page-username-wrapper">
                            <h1 className="text-header">{usernameState}</h1>
                            <Tooltip arrow placement="top" onClose={handleToolTipClose} title={toolTipText}>
                                <ForwardedShareIcon className="username-share-icon" onClick={handleCopyClick}/>
                            </Tooltip>
                        </div>
                        { userInfoObject && "tradingviewURL" in userInfoObject && userInfoObject.tradingviewURL !== "" &&
                            <div className="profile-page-user-info-tv">
                                <InsertLinkRoundedIcon />
                                <p className="text-base" onClick={OpenTradingViewProfile}>TradingView</p>
                            </div>
                        }
                        {/* <p className="text-base"> 27.3% avg profit (7 days)</p> */}
                    </div>

                    <div className="profile-page-social-container">
                        { userInfoObject && "twitterURL" in userInfoObject && userInfoObject.twitterURL !== "" && <SocialIcon target="_blank" url={userInfoObject.twitterURL} />}
                        { userInfoObject && "telegramURL" in userInfoObject && userInfoObject.telegramURL !== "" && <SocialIcon target="_blank" url={userInfoObject.telegramURL} />}
                        { userInfoObject && "instagramURL" in userInfoObject && userInfoObject.instagramURL !== "" && <SocialIcon target="_blank" url={userInfoObject.instagramURL} />}
                        { userInfoObject && "youtubeURL" in userInfoObject && userInfoObject.youtubeURL !== "" && <SocialIcon target="_blank" url={userInfoObject.youtubeURL} />}

                    </div>

                    
                </div>

                { (userInfoObject && userInfoObject.description) &&
                    <div className="profile-page-description glass-surface-module">
                        {/* <p className="text-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
                            <p className="text-base">{userInfoObject.description}</p>
                    </div>
                }

                {/* Hiding users trades if they have set their showPortfolio to private */}
                { showPortfolio ? (
                    <>
                        <RecentClosedTrades 
                            recentClosedTrades={recentClosedTrades} 
                            showRecentClosedTradesSpinner={showClosedTradesSpinner} 
                        />
        
                        <ProfileHoldingsChart 
                            holdingsData={holdingsData} 
                            showHoldingsChartSpinner={showHoldingsChartSpinner} 
                            includeUSD={includeUSD} 
                            setIncludeUSD={setIncludeUSD}
                        />
                    </>
                ) : (
                    <div className="profile-page-description glass-surface-module">
                        <p className="text-base">This user's trades are private.</p>
                    </div>
                )}
            </div>
        </DashboardLayout>
    );
};