import React, { useState } from "react";

import "./styles/Card.css";

import PortfolioValueModal from "./modals/PortfolioValueModal";

import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';

import UpArrow from "../../images/up_arrow.png";
import DownArrow from "../../images/down_arrow.png";

import { useTheme } from "../../hooks/useTheme";

const PortfolioValueCard = ({ portfolioValue, user }) => {
  const [modalState, setModalState] = useState(false);

  const { isMobile } = useTheme();

  const toggleModal = () => {
    setModalState(!modalState);
  };

  const netProfit = portfolioValue - parseFloat(user.usdDeposits);
  const netProfitPercent = (netProfit / parseFloat(user.usdDeposits)) * 100;

  const userJoinDateObject = new Date(user.dateJoined);

  const currentDateObj = new Date();
  const lineChartData = [
    {
      id: "portfolio value",
      color: "hsl(312, 70%, 50%)",
      data: [
        {
          x: `${
            userJoinDateObject.getMonth() + 1
          }/${userJoinDateObject.getDate()}`,
          y: 10000, // starting wallet balance of $10,000
        },
        {
          x: `${currentDateObj.getMonth() + 1}/${currentDateObj.getDate()}`,
          y: portfolioValue.toFixed(2),
        },
      ],
    },
  ];

  return (
    <>
      <div className="card glass-surface-module" onClick={() => !isMobile && toggleModal()}>

        <h1 className="text-header">Portfolio Balance</h1>
        
        <div className="card-info">
          <div className="card-position-info">
            <p className="card-position-value text-base">
              {portfolioValue.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </p>
            <p className={`card-position-value-subtext text-small ${netProfit > 0 ? "text-glass-green" : "text-glass-red"}`}>
              {netProfitPercent.toFixed(2)}%
            </p>
          </div>

          <div className="card-chart-display">

            <div className="portfolio-value-arrow">
              {/* {netProfit >= 0 ? <ArrowUpwardRoundedIcon className="text-glass-green" fontSize="inherit"/> : <ArrowDownwardRoundedIcon className="text-glass-red" fontSize="inherit"/> } */}
              {netProfit >= 0 ? <img src={UpArrow} /> : <img src={DownArrow} /> }

            </div>
            
          </div>

        </div>

      </div>

      
      {modalState && false && (
        <PortfolioValueModal
          toggleModal={toggleModal}
          portfolioValue={portfolioValue}
          netProfitPercent={netProfitPercent}
          lineChartData={lineChartData}
        />
      )}
    </>
  );
};

export default PortfolioValueCard;
