import DashboardLayout from "../../components/DashboardLayout/DashboardLayout";
import "./Integrations.css";

import { usePageTracking } from "../../hooks/usePageTracking";

import TradingViewWhiteLogo from "../../images/tradingview-media/logo/png/white/logo-square-light.png";
import PostManLogoOrange from "../../images/postman-media-kit/png/Postman-logo-vrtical-orange-2021.png";
import ZapierLogoBlack from  "../../images/zapier-media-kit/Logos/zapier-logo_black.png";
import TelegramLogo from "../../images/telegram-media-kit/Logo.png";

import { useNavigate } from "react-router-dom";

const IntegrationTile = ({image, imageBackgroundColor="white", title, description, isEnabled=true}) => {

    const navigate = useNavigate();

    usePageTracking();
    
    const integrationPaths = {
        TradingView: "/webhook",
        Postman: "/postman",
        Zapier: "/zapier",
        Telegram: "/telegram"
    }

    const handleNavigation = () => {
        isEnabled && navigate(integrationPaths[title]);
    }

    return(
        <div className={`glass-surface-module integration-tile ${isEnabled ? "enabled" : ""}`} onClick={handleNavigation}>
            <div className={`tile-image ${imageBackgroundColor}`}>
                <img src={image} />
            </div>

            <h1 className="text-header text-color-primary">{title}</h1>

            <p className="text-base text-color-secondary">{description}</p>

        </div>
    )
}

export const Integrations = ({}) => {

    return (
        <DashboardLayout page={"Integrations"}>
            <div className="integration-container">
                <IntegrationTile image={TradingViewWhiteLogo} imageBackgroundColor={"black"} title={"TradingView"} description={"Sending us signals from your TradingView indicators"} />
                <IntegrationTile image={PostManLogoOrange} title={"Postman"} description={"Coming soon..."} isEnabled={false}/>
                <IntegrationTile image={ZapierLogoBlack}  title={"Zapier"} description={"Coming soon..."} isEnabled={false}/>
                <IntegrationTile image={TelegramLogo} title={"Telegram"} description={"Coming soon..."} isEnabled={false}/>

            </div>
        </DashboardLayout>
    )
}