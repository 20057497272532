export const shuffleArray = (arr) => {
    let shuffled = [...arr];
    for (let i = shuffled.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
}

export const getColorShadeFromProfit = (profit, minValue, maxValue, getBorder=false) => {
    // Normalize the profits values to get a consistent scale
    const normalizedValue = (Math.abs(profit) - minValue) / (maxValue - minValue);

    // Define the lightness range (lighter for 0, darker for 1)
    const lightness = 76 - normalizedValue * 40; // 76% for light tint, 36% for darker shade

    const backgroundGreen = `hsla(168, 100%, ${lightness}%, 0.5)`;
    const borderGreen = `hsla(168, 100%, 36%, 1)`;

    const backgroundRed = `hsla(350, 90%, ${lightness}%, 0.5)`;
    const borderRed = `hsla(350, 90%, 64%, 1)`;

    return profit > 0 ? [backgroundGreen, borderGreen] : [backgroundRed, borderRed];
}


// Need separate function here for Pie chart since we do not have a black background to overlay 

export const getColorShadesForPieChart = (numberOfItems, darkestShade = 'hsl(216, 83%, 60%)') => {

    const highestLightness = 90;
    const lowestHue = 200
    const [h, s, l, a] = parseHSLA(darkestShade);
    const deltaL = Math.round((highestLightness - l) / numberOfItems);
    const deltaH = Math.round((h - lowestHue) / numberOfItems); 

    const colors = []
    for (let i = 0; i < numberOfItems; i++) {
        colors.push(`hsl(${h - (deltaH * (i+1))}, ${s}%, ${l + (deltaL * (i+1))}%)`)
    }

    return colors
}

// Function to take Greens and Reds used for TreeMap and overlay it over a black-ish background - REVISIT In future - not used right now
// This is needed for places like Nivo Pie chart since it does not accept custom nodes
// Helper: Convert HSLA to RGBA
const hslaToRgba = (h, s, l, a = 1) => {
    h /= 360;
    s /= 100;
    l /= 100;
    let r, g, b;

    if (s === 0) {
        r = g = b = l; // Achromatic
    } else {
        const hueToRgb = (p, q, t) => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hueToRgb(p, q, h + 1 / 3);
        g = hueToRgb(p, q, h);
        b = hueToRgb(p, q, h - 1 / 3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255), a];
};

// Helper: Convert RGBA to HSLA
const rgbaToHsla = (r, g, b, a = 1) => {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // Grayscale
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h *= 60;
    }
    return [Math.round(h), Math.round(s * 100), Math.round(l * 100), a];
};

// Main: Blend two HSLA colors
export const blendHSLA = (fgHsla, bgHsla = "hsla(180, 13%, 5%, 0.5)") => {
    const [h1, s1, l1, a1] = parseHSLA(fgHsla);
    const [h2, s2, l2] = parseHSLA(bgHsla);

    // Convert HSLA to RGBA
    const [r1, g1, b1, a] = hslaToRgba(h1, s1, l1, a1);
    const [r2, g2, b2] = hslaToRgba(h2, s2, l2);

    // Blend RGBA values
    const r = Math.round(r1 * a + r2 * (1 - a));
    const g = Math.round(g1 * a + g2 * (1 - a));
    const b = Math.round(b1 * a + b2 * (1 - a));

    // Convert back to HSLA
    return rgbaToHsla(r, g, b, 1);
};

const parseHSLA = (hslaString) => {
    // Match the HSLA pattern and extract values
    const match = hslaString.match(/hsla?\((\d+),\s*([\d.]+)%,\s*([\d.]+)%,?\s*([\d.]+)?\)/i);
    
    if (!match) {
        throw new Error("Invalid HSLA format");
    }

    // Extract and return H, S, L, and A (defaults to 1 if not present)
    const [, h, s, l, a = 1] = match.map(Number);
    return [h, s, l, a];

};