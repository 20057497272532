import React, { useState, useRef } from "react";
import "./Popover.css";

const Popover = ({ trigger, content, position = "bottom" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef(null);

  return (
    <div
      className="popover-container"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div className="popover-trigger">{trigger}</div>

      {isOpen && (
        <div className={`popover-box ${position}`} ref={popoverRef}>
          {content}
        </div>
      )}
    </div>
  );
};

export default Popover;