import React from "react";

import "./styles/PageFour.css";

import SampleStrategies from "../assets/sample-strategies.png";
import NewsletterImage from "../assets/newsletter_image.png";

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const PageFour = () => {
  return (
    <section className="page-four landing-page-padding">
      <div className="page-wrapper">
        <div className="page-four-header">
          <h1 className="text-heading text-dark-blue">
            Extensive tutorials
          </h1>
          <div className="coming-soon coming-soon--desktop">and growing...</div>
        </div>

        <div className="page-four-sample-strategies">
          <div className="page-four-column-one">

            <div className="page-four-process" onClick={() => window.open("https://docs.chart.observer/knowledge-base/manual-crypto-trading/manual-trading-explained/", '_blank', 'noopener,noreferrer')}>
              <div className="process-number">
                <h1>1</h1>
              </div>
              <div className="process-content">
                <h2>Quick Start Guide<KeyboardArrowRightIcon/></h2>
                <p>
                  For new users, we have prepared a guide to our simulated trading interface.
                  The video tutorial will walk you through the process of opening and closing trades the easy way.
                </p>
              </div>
            </div>

            <div className="page-four-process" onClick={() => window.open("https://docs.chart.observer/knowledge-base/category/cryptocurrency-pairs/", '_blank', 'noopener,noreferrer')}>
              <div className="process-number">
                <h1>2</h1>
              </div>
              <div className="process-content">
                <h2>List of supported cryptocurrency tokens<KeyboardArrowRightIcon/></h2>
                <p>
                  Our platform supports hundreds of cryptocurrencies.  Explore the world beyond Bitcoin and Ethereum. 
                  The tokens available are based on your current subscription level.
                </p>
              </div>
            </div>

            <div className="page-four-process" onClick={() => window.open("https://docs.chart.observer/knowledge-base/category/automating-crypto-trades/", '_blank', 'noopener,noreferrer')}>
              <div className="process-number">
                <h1>3</h1>
              </div>
              <div className="process-content">
                <h2>Send trade signals from other charting tools <KeyboardArrowRightIcon/></h2>
                <p>
                  Learn how to buy and sell triggers in your favorite charting tools, like Tradingview for example. 
                  Discover how to send these "alerts" here, to be turned into simulated trades.
                </p>
              </div>
            </div>

          </div>
          <div className="page-four-column-two">
            <div className="page-four-column-two-image-container">
              <img className="sample-strategies-image" src={SampleStrategies} alt="sample strategies" />
            </div>
          </div>
          <div className="coming-soon coming-soon--mobile">and growing...</div>
        </div>
      </div>
      <div className="page-four-background-image-top-right">
        <img
          alt="background graphic 1"
          className="page-four-background-image-top-right-img"
          src={NewsletterImage}
        />
      </div>
      <div className="page-four-background-image-bottom-right">
        <img
          alt="background graphic 2"
          className="page-four-background-image-bottom-right-img"
          src={NewsletterImage}
        />
      </div>
    </section>
  );
};

export default PageFour;
