import { Typography } from "@mui/material";

const fontSize = 14;

export const columns = [
    { 
        field: "id", 
        headerName: "Trade Index",
        flex: 0.5,
        cellClassName: "--cell"
    },
    { 
        field: "tokenId", 
        headerName: "Token", 
        flex: 1,
        cellClassName: "--cell --token-cell",
        renderCell: ({ row: {tokenId}}) => {
            return(
                <>
                    {tokenId.slice(0,-3)}
                </>
            )
        }
    },
    { 
        field: "amount", 
        headerName: "Amount",
        flex: 1,
        cellClassName: "--cell",
        renderCell: ({row: {amount}}) => {
            return(
                <Typography fontSize={fontSize} >
                    {(amount % 1 === 0) ? amount.toFixed(0) : (amount > 9999 ? amount.toFixed(0) : (amount < 10 ? amount.toFixed(5) : amount.toFixed(2)))}
                </Typography>
            )
        }
    },
    // { 
    //     field: "totalCost", 
    //     headerName: "Total Cost",
    //     flex: 1,
    //     cellClassName: "--cell",
    //     renderCell: ({ row: {totalCost}}) => {
    //         return(
    //             <Typography fontSize={fontSize}>
    //                 {totalCost.toLocaleString("en-US", {style: "currency", currency: "USD"})}
    //             </Typography>
    //         );
    //     }
    // },
    { 
        field: "avgPrice",
        headerName: "Avg. Price",
        flex: 1,
        cellClassName: "--cell",
        renderCell: ({ row: {avgPrice}}) => {
            return(
                <Typography fontSize={fontSize}>
                    {/* {avgPrice.toLocaleString("en-US", {style: "currency", currency: "USD"})} */}
                    {avgPrice < 1 ? `$${(avgPrice).toFixed(6)}` : avgPrice.toLocaleString("en-US", {style: "currency", currency: "USD"})}
                </Typography>
            );
        }
    },
    { 
        field: "closePrice",
        headerName: "Close Price",
        flex: 1,
        cellClassName: "--cell",
        renderCell: ({ row: {closePrice}}) => {
            return(
                <Typography fontSize={fontSize}>
                    {/* {avgPrice.toLocaleString("en-US", {style: "currency", currency: "USD"})} */}
                    {closePrice < 1 ? `$${(closePrice).toFixed(6)}` : closePrice.toLocaleString("en-US", {style: "currency", currency: "USD"})}
                </Typography>
            );
        }
    },
    // { 
    //     field: "currentPrice",
    //     headerName: "Current Price",
    //     flex: 1,
    //     cellClassName: "--cell",
    //     renderCell: ({ row: {currentPrice}}) => {
    //         // Format price to have 2 or more decimal places (if it has a decimal)
    //         // const priceString = currentPrice.toString();
    //         // var formatedPrice = currentPrice;
    //         // if (priceString.includes(".")) {
    //         //     if (priceString.split(".")[1].length < 2) {
    //         //         formatedPrice = currentPrice.toFixed(2);
    //         //     }
    //         // }
    //         return(
    //             <Typography fontSize={fontSize}>
    //                 {currentPrice < 1 ? `$${currentPrice.toFixed(6)}` : currentPrice.toLocaleString("en-US", {style: "currency", currency: "USD"})}
    //             </Typography>
    //         );
    //     }
    // },
    // { 
    //     field: "age", 
    //     headerName: "Age",
    //     flex: 1,
    //     renderCell: ({ row: { age }}) => {
    //         let formatAge = ""
    //         if ( age < 1) {
    //             formatAge = `${Math.floor(age * 60)}m`;
    //         } else if (age < 24) {
    //             formatAge = `${Math.floor(age)}h`;
    //         } else {
    //             formatAge = `${Math.floor(age / 24)}d`;
    //         }
            
    //         return(
    //             <Typography fontSize={fontSize}>
    //                 {formatAge}
    //             </Typography>
    //         );
    //     }
    // },
    {  
        field: "closeDate", 
        headerName: "Close Date",
        flex: 1,
        cellClassName: "--cell",
        renderCell: ({ row: { closeDate }}) => {
            // Parse the UTC date string
            // Adding Z to end of date since it is in UTC but we dont return that
            // const utcDate = new Date(date+"Z");

            // // Format the date in the current time zone
            // const formatter = new Intl.DateTimeFormat('en-US', {
            //     day: '2-digit',
            //     month: '2-digit',
            //     year: '2-digit',
            //     hour: '2-digit',
            //     minute: '2-digit',
            //     hour12: false, // Use 24-hour format
            //     timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            // });

            return(
                <Typography fontSize={fontSize}>
                    {closeDate}
                </Typography>
            )
        }
    },
    {  
        field: "txnSource", 
        headerName: "Source",
        flex: 1,
        cellClassName: "--cell",
    },
    { 
        field: "profit", 
        headerName: "Profit",
        flex: 1,
        renderCell: ({ row: { profit }}) => {
            const profitColor = profit >= 0
                ? "#00A652" //green
                : "#ED1B24" //red
            return(
                <Typography color={profitColor} fontSize={fontSize}>
                    {profit.toFixed(2)}%
                </Typography>
            );
        }
    },
]
