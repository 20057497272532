import { ResponsivePie } from '@nivo/pie'

import { chartTheme } from '../cards/modals/ChartTheme';

import { useTheme } from '../../hooks/useTheme';

const PieChart = ({chartData, chartSettings}) => {

    const { isDarkMode } = useTheme();

    const textColor = isDarkMode ? "#e0e0e0" : "#333333";

    return(
        <ResponsivePie
            theme={chartTheme(isDarkMode)}
            data={chartData}
            margin={chartSettings.margin}
            valueFormat=" >-$.2f"
            colors={chartSettings.dataColors ? { datum: 'data.color' } : { scheme: 'blues'}}
            // colors={{ datum: 'data.color' }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={2}
            startAngle={chartSettings.startAngle}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        chartSettings.borderColorModifier
                    ]
                ]
            }}
            enableArcLinkLabels={true}
            arcLinkLabelsTextColor={textColor}
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLinkLabelsSkipAngle={chartSettings.arcLinkLabelsSkipAngle}
            arcLinkLabelsDiagonalLength={chartSettings.arcLinkLabelsDiagonalLength}
            arcLinkLabelsStraightLength={chartSettings.arcLinkLabelsStraightLength}
            arcLinkLabelsOffset={0}
            enableArcLabels={chartSettings.enableArcLabels}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        6
                    ]
                ]
            }}
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                }
            ]}
            fill={[
                {
                    match: {
                        id: 'ruby'
                    },
                    id: 'dots'
                },
                {
                    match: {
                        id: 'c'
                    },
                    id: 'dots'
                },
                {
                    match: {
                        id: 'go'
                    },
                    id: 'dots'
                },
                {
                    match: {
                        id: 'python'
                    },
                    id: 'dots'
                },
                {
                    match: {
                        id: 'scala'
                    },
                    id: 'lines'
                },
                {
                    match: {
                        id: 'lisp'
                    },
                    id: 'lines'
                },
                {
                    match: {
                        id: 'elixir'
                    },
                    id: 'lines'
                },
                {
                    match: {
                        id: 'javascript'
                    },
                    id: 'lines'
                }
            ]}
            legends={[]}
        />
    );
}

export default PieChart;