import React from "react";
import Spinner from "../../components/loadingAnimations/Spinner";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { columns } from "./columns";

export const RecentClosedTrades = ({recentClosedTrades, showRecentClosedTradesSpinner}) => {

    if (showRecentClosedTradesSpinner) {
        return(
            <div className="profile-page-closed-trades glass-surface-module">
                <h1 className="text-header">Recent Closed Trades</h1>

                <Spinner spinnerColor="grey" />
            </div>
        );
    }

    if (recentClosedTrades === null) {
        return(
            <div className="profile-page-closed-trades glass-surface-module">
                <h1 className="text-header">Recent Closed Trades</h1>
                <p className="text-base">User has no recently closed trades.</p>
            </div>
        );
    }

    return(
        <div className="profile-page-closed-trades glass-surface-module">
            <h1 className="text-header">Recent Closed Trades</h1>

                <DataGrid
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                status: false,
                                id: false,
                            },
                        }
                    }}
                    rows={recentClosedTrades}
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                />
                
        </div>
    );
}