import React, { useEffect, useState } from "react";
import "./LeaderboardTable.css";
import Spinner from "../loadingAnimations/Spinner";
import { useNavigate } from "react-router-dom";


export const LeaderboardTable = ({leaderBoadData}) => {

    const [countPerPage, setCountPerPage] = useState(25);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);

    const [tradesForPage, setTradesForPage] = useState(null);

    const handlePageSelection = (pageNumber) => {

        const pageCount = Math.ceil(leaderBoadData.length / countPerPage);

        if ( pageNumber <= pageCount && pageNumber > 0) {
            setCurrentPageNumber(pageNumber);
        }
    }

    useEffect(() => {
        if (leaderBoadData && leaderBoadData.length > 0) {
            const leaderBoadDataSlice = leaderBoadData.slice((currentPageNumber-1) * countPerPage,(currentPageNumber * countPerPage));
            setTradesForPage(leaderBoadDataSlice);
        }
    }, [currentPageNumber, countPerPage, leaderBoadData]);

    return(
        <div className="lb-table">
            <h1 className="text-header">Best Trades</h1>

            {leaderBoadData && leaderBoadData.length > 0 ? (
                <div className="lb-table-container">
                    <div className="lb-table-scrollable-container">
                        <TableRowHeader />
                        {tradesForPage && tradesForPage.map((tradeData, index) => (
                            <TableRow 
                                key={index} 
                                tradeData={tradeData} 
                                index={index + (currentPageNumber-1) * countPerPage}
                            />
                        ))}
                    </div>
                    {currentPageNumber &&
                        <TableRowFooter 
                            countPerPage={countPerPage} 
                            currentPageNumber={currentPageNumber}
                            handlePageSelection={handlePageSelection}
                            numberOfEntries={leaderBoadData.length}
                        />
                    }
                </div>
            ) : (
                <div className="glass-surface-module leaderboard-spinner">
                    <Spinner spinnerColor="grey"/>
                </div>
            )}

        </div>
    );
}

const TableRowHeader = () => {
    return(
        <div className="lb-table-header">
            <div className="lb-table-col">
                Rank
            </div>
            <div className="lb-table-col">
                Username
            </div>
            <div className="lb-table-col">
                Token Pair
            </div>
            <div className="lb-table-col">
                Profit
            </div>
        </div>
    )
}
const TableRowFooter = ({countPerPage, currentPageNumber, handlePageSelection, numberOfEntries}) => {

    const pageCount = Math.ceil(numberOfEntries / countPerPage);

    const renderPageSelection = () => {
        let elements = [];
        for (let i = 0; i < pageCount; i++) {
            elements.push(
                <p key={i+1} className={currentPageNumber === i+1 ? "lb-table-page-select-number-active" : "lb-table-page-select-number"} onClick={ () => handlePageSelection(i+1)}>{i+1}</p>
            )
        }
        return elements;
    }

    return(
        <div className="lb-table-footer">
            <div className="lb-table-showing-container">
                <p>Showing {countPerPage} of {numberOfEntries} entries</p>
            </div>
            <div className="lb-table-page-select-container">
                <p 
                    className={currentPageNumber > 1 ? "lb-table-page-select-button" : "lb-table-page-select-button-disabled" }
                    onClick={ currentPageNumber > 1 ? () => handlePageSelection(currentPageNumber - 1) : null }
                >
                    Previous
                </p>
                {renderPageSelection()}
                <p 
                    className={currentPageNumber < pageCount ? "lb-table-page-select-button" : "lb-table-page-select-button-disabled"}
                    onClick={ currentPageNumber < pageCount ? () => handlePageSelection(currentPageNumber + 1) : null }
                >
                    Next
                </p>
            </div>
        </div>
    )
}

const TableRow = ({tradeData, index}) => {

    const navigate = useNavigate();

    const handleProfileClick = async () => {
        if ("username" in tradeData) {
            const username = tradeData.username;
            navigate(`/profile/${username}`);
        }
    }

    return(
        <div className="lb-table-row" onClick={handleProfileClick}>
            <div className="lb-table-col">
                <p className="text-small">{index + 1}</p>
            </div>
            <div className="lb-table-col">
                <p className="text-small lb-table-username">{tradeData.username}</p>
            </div>
            <div className="lb-table-col">
                <p className="text-small">{tradeData.tokenPair}</p>
            </div>
            {/* <div className="lb-table-col">
                <p className="text-small">{traderData.tradeCount}</p>
            </div> */}
            <div className="lb-table-col">
               <p className="text-small">{tradeData.profit.toFixed(2)}%</p> 
            </div>
        </div>
    )
}