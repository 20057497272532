import { tradeAgeCalculatorHours } from "./tradeAgeCalculator";


export const formatDataForGrid = (transactionData, coinbaseData) => {

    const openPositions = {};
    transactionData.forEach(trade => {
        var token = trade.tokenPair.S;
        if (trade.isOpen.BOOL) {
            if (openPositions.hasOwnProperty(trade.tokenPair.S)) {
                openPositions[token].push(trade);
            } else {
                openPositions[token] = [trade];
            }
        }
    });


    const formatedOpenPositionArray = [];
    // Loop through Trade Tokens
    for (const key in openPositions) {
        let amount = 0;
        let totalCost = 0;
        let dateOpened = new Date().getTime();
    
        // Loop through the trades for each coin
        openPositions[key].forEach(trade => {
            const tradeAmount = parseFloat(trade.amount.N);
            const tradePrice = parseFloat(trade.txnPrice.N);
            const tradeDate = new Date(trade.txnDate.S + "Z").getTime();
    
            amount += tradeAmount;
            totalCost += tradePrice * tradeAmount;
            dateOpened = Math.min(dateOpened, tradeDate);
        });
    
        const coinbaseTokenName = key.slice(0, -3);
        const currentPrice = (coinbaseData) ? (1 / coinbaseData["data"]["rates"][coinbaseTokenName]) : "N/a";
        
        // Prevent division by zero
        const avgPrice = amount ? totalCost / amount : 0;
        const profit = (currentPrice === "N/a" || totalCost === 0) ? "N/a" : (((currentPrice * amount) - totalCost) / totalCost) * 100;
    
        const tradeAge = tradeAgeCalculatorHours(dateOpened, true);
    
        formatedOpenPositionArray.push({
            id: key,
            amount,
            totalCost,
            avgPrice,
            currentPrice,
            age: tradeAge,
            profit
        });
    }

    return(formatedOpenPositionArray);
}