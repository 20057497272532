import HoldingsCard from "../cards/HoldingsCard";
import InvestedCard from "../cards/InvestedCard";
import PortfolioValueCard from "../cards/PortfolioValueCard";

import "./PortfolioMetrics.css"

import { useAuth } from "../../hooks/useAuth";
import { CardSpinner } from "../cards/CardSpinner";



const PortfolioMetrics = ({ openPositionData, portfolioValue, tradePerformance, userBalance, showTreeMapCardSpinner }) => {

  const { user } = useAuth();
  
  return (
    <div className="grid-column4 portfolio-metrics">
      {portfolioValue && userBalance ? (
        <PortfolioValueCard portfolioValue={portfolioValue} user={user} userBalance={userBalance} />
      ) : (
        <CardSpinner />
      )}
      {userBalance ? (
        <InvestedCard userBalance={userBalance} openPositionData={openPositionData} />
      ) : (
        <CardSpinner />
      )}
      { userBalance ? (
        <HoldingsCard userBalance={userBalance} openPositionData={openPositionData} />
      ) : (
        <CardSpinner />
      )}
      {/* {!showTreeMapCardSpinner ? (
        <TreeMapCard openPositionData={openPositionData} />
      ) : (
        <CardSpinner />
      )} */}
      {/* {tradePerformance ? (
        <TradePerformanceCard tradePerformance={tradePerformance} />
      ) : (
        <CardSpinner />
      )} */}
    </div>
  );
};

export default PortfolioMetrics;
